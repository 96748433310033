import React, { memo } from "react";
// import { MIN_SEARCH_TEXT, MAX_SEARCH_WORDS } from "constants";

import {
    Box,
} from '@mui/material';

import "./styles.css";


const PrintTpl_2Component = ({ printData }) => {

    console.log(printData);

    let { patientData, currentHistoryItem, currentEvent, modalData = {} } = printData;


    patientData = {
        ...patientData,
        birthday: patientData.birthday ? new Date(patientData.birthday).toLocaleDateString() : "",
    };


    currentHistoryItem = {
        ...currentHistoryItem,
        hospitalizationDate: currentHistoryItem.hospitalizationDate ? new Date(currentHistoryItem.hospitalizationDate).toLocaleString(undefined, { timeStyle: "short", dateStyle: "short", }) : "",
        dischargeDate: currentHistoryItem.dischargeDate ? new Date(currentHistoryItem.dischargeDate).toLocaleString(undefined, { timeStyle: "short", dateStyle: "short", }) : "",
        evacuationLocation: currentHistoryItem.evacuationLocation === "в строй" || currentHistoryItem.evacuationLocation === "В строй" ? "строй" : currentHistoryItem.evacuationLocation,

        /**N EW */
        title1: currentHistoryItem.complaint || "-",
        title2: currentHistoryItem.complaint || "-",
        complaint: currentHistoryItem.complaint || "-",
        recommendations: currentHistoryItem.recommendations ? currentHistoryItem.recommendations.trim() : "",
        // complaint: currentHistoryItem.complaint || "-",
        // complaint: currentHistoryItem.complaint || "-",
    };

    currentEvent = {
        ...currentEvent,
        eventDate: currentEvent.eventDate ? new Date(currentEvent.eventDate).toLocaleString(undefined, { timeStyle: "short", dateStyle: "short", }) : "",
    };



    let currentDate = new Date().toLocaleString(undefined, { timeStyle: "short", dateStyle: "short", });

    let mhNumber = currentHistoryItem.number ? currentHistoryItem.number : "_________________________";


    let statusEvaqArr = [];
    let statusEvaqArrTitle = [];

    if (currentHistoryItem.status) {
        statusEvaqArr.push(currentHistoryItem.status);
        statusEvaqArrTitle.push("Статус");
    }

    if (currentHistoryItem.evacuationLocation) {
        statusEvaqArr.push(currentHistoryItem.evacuationLocation);
        statusEvaqArrTitle.push("Направление убытия");
    }
    let statusEvaq = statusEvaqArr.join(" / ");
    let statusEvaqTitle = statusEvaqArrTitle.join(" / ");

    let anamnez = "";

    if (currentEvent.eventDate) {
        anamnez += `Дата и время ранения: ${currentEvent.eventDate}`;
    }

    if (currentEvent.circumstances) {
        anamnez += ` Обстоятельства ранения: ${currentEvent.circumstances}`;

    }
    if (currentEvent.nature) {
        anamnez += ` Характер ранения: ${currentEvent.nature}`;

    }

    if (currentHistoryItem.anamnesis) {
        if (anamnez) {
            anamnez += ", ";
        }
        anamnez += `${currentHistoryItem.anamnesis}`;
    }

    const makePersonnelRows = () => {
        let row1 = [...[patientData.suid, patientData.fio, patientData.birthday].filter(n => n)].join(", ") || "";
        let row2 = patientData.militaryUnitCode || "";
        let row3 = patientData.militaryRank || "";
        let row4 = "";
        let row5 = `Находился на лечении с ${currentHistoryItem.hospitalizationDate || "___________"} по ${currentHistoryItem.dischargeDate || "___________"}`;


        let unit = "";
        let unit2 = "";
        let unit2Array = [];


        if (patientData.division) {
            unit += patientData.division;
        }

        if (patientData.militaryDistrict) {
            unit2Array.push(patientData.militaryDistrict);
        }

        if (patientData.rota) {
            unit2Array.push(patientData.rota);
        }

        if (patientData.platoon) {
            unit2Array.push(patientData.platoon);
        }

        unit2 = unit2Array.join(", ");

        if (unit && unit2) {
            unit += ` (${unit2})`;
        }

        if (!unit && unit2) {
            unit = unit2;
        }

        row4 = unit;

        return [row1, row2, row3, row4, row5].filter(n => n);

        /* Место ранения: {currentEvent.location || "-"}<br /> */
    }

    return <>
        <div className="page print page-nobreak">
            <style type="text/css" media="print">
                {" @page { size: portrait; } "}
            </style>
            <table className="table_2">
                <tbody>
                    <tr>
                        <td className="bright_1">

                            <table className="table_2">
                                <tbody>
                                    <tr>
                                        <td align="center"><img src="/img/mo-gerb.png" alt="" width="80" /></td>
                                    </tr>
                                    <tr>
                                        <td className="bheader" height="40">Военная медицинская организация _________________________{/*01069 (н. п. Красный Октябрь)*/}</td>
                                    </tr>
                                    <tr>
                                        <td className="bheader">ПЕРЕВОДНОЙ ЭПИКРИЗ № {mhNumber}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <table className="personnel_1">
                                                <tr>
                                                    <td>
                                                        <div className="bcontent_1">
                                                            {makePersonnelRows().map(row => {
                                                                return <Box>{row}</Box>;
                                                            })}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="bborder-flex_1">
                                            <span className="btitle_1">Диагноз при поступлении:</span>
                                            <span className="bcontent_1">{currentHistoryItem.disease || ""}</span>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="bborder-flex_1">
                                            <span className="btitle_1">Жалобы:</span>
                                            <span className="bcontent_1">{currentHistoryItem.complaint || ""}</span>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="bborder-flex_1">
                                            <div className="btitle_1">Анамнез:</div>
                                            <span className="bcontent_1">
                                                {anamnez}
                                            </span>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="bborder-flex_1">
                                            <span className="btitle_1">Инструментально-лабораторные исследования:</span>
                                            <span className="bcontent_1">{currentHistoryItem.diagnosisStudies || ""}</span>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="bborder-flex_1">
                                            <span className="btitle_1">Заключительный диагноз:</span>
                                            <span className="bcontent_1">{currentHistoryItem.diagnosisFinal || ""}</span>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="bborder-flex_1">
                                            <span className="btitle_1">Операция:</span>
                                            <span className="bcontent_1">{currentHistoryItem.therapy || ""}</span>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="bborder-flex_1">
                                            <span className="btitle_1">Оказанная помощь:</span>
                                            <span className="bcontent_1">{currentHistoryItem.helping || ""}</span>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="bborder-flex_1">
                                            <span className="btitle_1">Фармакотерапия:</span>
                                            <span className="bcontent_1">{currentHistoryItem.pharmacotherapy || ""}</span>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="bborder-flex_1">
                                            <span className="btitle_1">Риски:</span>
                                            <span className="bcontent_1">{currentHistoryItem.risks || ""}</span>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="bborder-flex_1">
                                            <span className="btitle_1">Дата и время эвакуации/выписки:</span>
                                            <span className="bcontent_1">{currentHistoryItem.dischargeDate || ""}</span>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="bborder-flex_1">
                                            <span className="btitle_1">Направление эвакуации:</span>
                                            <span className="bcontent_1">{currentHistoryItem.referral || ""}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bborder-flex_1">
                                            <span className="btitle_1">Статус/Направление убытия:</span>
                                            <span className="bcontent_1">{statusEvaq || ""}</span>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="bborder-flex_1">
                                            <span className="btitle_1">Вид эвакуации:</span>
                                            <span className="bcontent_1">{currentHistoryItem.evacuationType || ""}</span>
                                        </td>
                                    </tr>






                                    {/* <tr>
                                        <td className="bborder-flex_1">
                                            <span className="btitle_1">Клинический исход:</span>
                                            <span className="bcontent_1">{currentHistoryItem.hz2 || ""}</span>
                                        </td>
                                    </tr>


                                    <tr>
                                        <td className="bborder-flex_1">
                                            <span className="btitle_1">Рекомендации:</span>
                                            <span className="bcontent_1">{currentHistoryItem.recommendations || ""}</span>
                                        </td>
                                    </tr> */}


                                    <tr>
                                        <td></td>
                                    </tr>


                                    {modalData.p1 && <tr>
                                        <td className="bborder-flex_1">
                                            <div className="btitle_1 bold">Справка о ранении (контузии, травме, увечье), в соответствии с указом Президента РФ<br />№ {modalData.p1_number || "________"} от {modalData.p1_date || "________"} г. оформлена.</div>
                                        </td>
                                    </tr>}


                                    {modalData.p2 && <tr>
                                        <td className="bborder-flex_1">
                                            <div className="btitle_1 bold">Справка на социальные выплаты в соответствии с Указом Президента РФ не оформлялась - ВВК не проводилось.</div>
                                        </td>
                                    </tr>}


                                    {modalData.p3 && <tr>
                                        <td className="bborder-flex_1">
                                            <div className="btitle_1 bold">Освидетельствован ВВК {modalData.p3_date || "________"} года, в соответствии с Перечнем, утвержденным постановлением Правительства Российской Федерации от 29.07.1998 года №855, увечье - в перечень {modalData.p3_entire || "________"}</div>
                                        </td>
                                    </tr>}


                                    {modalData.p4 && <tr>
                                        <td className="bborder-flex_1">
                                            <div className="btitle_1 bold">Медицинское освидетельствование на определение тяжести травмы (увечья) не проводилось.</div>
                                        </td>
                                    </tr>}

                                    {modalData.p5 && <tr>
                                        <td className="bborder-flex_1">
                                            <div className="btitle_1 bold">Эвакуация: {modalData.p5_evaqType || "________"}, очередь {modalData.p5_queue || "________"}</div>
                                        </td>
                                    </tr>}



                                    <tr>
                                        <td></td>
                                    </tr>

                                    <tr>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                    </tr>

                                    {/* <tr>
                                        <td className="bborder-flex noborder">

                                            <table className="w100">
                                                <tbody>
                                                    <tr>
                                                        <td className="w40">Начальник медицинской службы<br /> Медроты 41 МСП, Красный октябрь</td>
                                                        <td className="w40 borderBottom"></td>
                                                        <td className="w20">
                                                            ст. л-т м/с
                                                            <br />
                                                            Иванов Э.А.
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </td>
                                    </tr> */}
                                    <tr>
                                        <td className="bborder-flex noborder">

                                            <table className="w100">
                                                <tbody>
                                                    <tr>
                                                        <td className="w40">Лечащий врач</td>
                                                        <td className="w40 borderBottom"></td>
                                                        <td className="w20">{currentHistoryItem.doctor}</td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                    </tr>
                                    {currentHistoryItem.wardRegime && <tr>
                                        <td className="bborder-flex noborder">

                                            <table className="w100">
                                                <tbody>
                                                    <tr>
                                                        <td className="w40"></td>
                                                        <td className="w40 borderBottom"></td>
                                                        <td className="w20">{currentHistoryItem.wardRegime}</td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </td>
                                    </tr>}
                                </tbody>
                            </table>




                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </>;
}

export const PrintTpl_2 = memo(PrintTpl_2Component);
