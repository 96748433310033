import React from 'react';
import dayjs from 'dayjs';

import { fields, buttons, dialogs } from '../app/thema';

import {
    Grid, Dialog, DialogTitle, DialogContent, DialogActions, 
    List, ListItem, ListItemButton, ListItemAvatar, ListItemText,
    Avatar
} from '@mui/material';
import { TextFld, PasswordFld } from '../components/Fields';
import { StartIconBtn, EndIconBtn } from '../components/Buttons';
import { SelectCbx } from '../components/ComboBoxs';
import { SelectAllTransferList } from '../components/TransferList';

import PersonIcon from '@mui/icons-material/Person';
import DescriptionIcon from '@mui/icons-material/Description';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import AddIcon from '@mui/icons-material/Add';
import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined';
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';


const getDateView = (val) => {
    let date = new Date(dayjs(val ?? undefined));
    if (val === undefined) {
        return '-';
    }

    return dayjs(date).format('DD.MM.YYYY');
}

const getDateTimeView = (val) => {
    let date = new Date(dayjs(val ?? undefined));
    if (val === undefined) {
        return '-';
    }

    return dayjs(date).format('DD.MM.YYYY HH:mm');
}

/**
 * Компонент диалоговое окно выбора личного состава
 * @param { Object } props передаваемые параметры в компонент
 * @returns компонент
 */
function PersonnalDlg(props) {
    const { thema, elems, title, selectedValue, open, onClose, } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (val) => {
        onClose(val);
    };

    const GetPrimary = (val) => {
        return (
            <>
                {val.fullName}
            </>
        )
    }

    const GetSecondary = (val) => {
        return (
            <>
                Дата рождения: {getDateView(val.birthday)}
            </>
        )
    };

    return (
        <Dialog
            onClose={handleClose} open={open}
        >
            <DialogTitle>
                {title}
            </DialogTitle>
            <List
                sx={{
                    pt: 0
                }}
            >
                {elems.map((item) => (
                    <ListItem disableGutters key={item.guid}>
                        <ListItemButton
                            onClick={() => handleListItemClick(item)}
                        >
                            <ListItemAvatar>
                                <Avatar
                                    sx={{
                                        bgcolor: thema.bgcolor,
                                        color: thema.color
                                    }}>
                                    <PersonIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={GetPrimary(item)}
                                secondary={GetSecondary(item.birthday)}
                            />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Dialog >
    )
}

/**
 * Компонент диалоговое окно выбора с возможностью добавления новой записи личного состава 
 * @param { Object } props передаваемые параметры в компонент
 * @returns компонент
 */
function PersonnalByInsertDlg(props) {
    const { thema, elems, title, selectedValue, open, onClose, } = props;

    const GetPrimary = (val) => {
        return (
            <>
                {val.fullName}
            </>
        )
    }

    const GetSecondary = (val) => {
        return (
            <>
                Дата рождения: {getDateView(val.birthday)}
            </>
        )
    };

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };

    const handleListItemNewClick = () => {
        onClose(null);
    }

    return (
        <Dialog
            onClose={handleClose} open={open}
        >
            <DialogTitle>
                <Grid
                    container spacing={1}
                >
                    <Grid
                        item
                    >
                        <ManageSearchIcon />
                    </Grid>
                    <Grid
                        item
                    >
                        {title}
                    </Grid>
                </Grid>
            </DialogTitle>
            <List
                sx={{
                    pt: 0
                }}
            >
                <ListItem disableGutters>
                    <ListItemButton
                        autoFocus
                        onClick={() => handleListItemNewClick()}
                    >
                        <ListItemAvatar>
                            <Avatar>
                                <AddIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Добавить военнослужащего" />
                    </ListItemButton>
                </ListItem>
                {elems.map((item, i) => (
                    <ListItem disableGutters key={i}>
                        <ListItemButton
                            onClick={() => handleListItemClick(item)}
                        >
                            <ListItemAvatar>
                                <Avatar
                                    sx={{
                                        bgcolor: thema.bgcolor,
                                        color: thema.color
                                    }}>
                                    <PersonIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={GetPrimary(item)}
                                secondary={GetSecondary(item)}
                            />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Dialog >
    )
}

/**
 * Компонент диалоговое окно выбора/добавления события с этапом эвакуации
 * @param { Object } props передаваемые параметры в компонент
 * @returns компонент
 */
function EventDlg(props) {
    const { thema, elems, title, selectedValue, open, onClose, } = props;

    const GetPrimary = (item) => {
        return (
            <>
                Время обращения: {getDateTimeView(item.dateOfVisits)}<br />
                Место оказания медицинской помощи: {item.locationMedicalAssistance}
            </>
        )
    }

    const GetSecondary = (item) => {
        return (
            <>
                Диагноз: {item.diagnosis}
            </>
        )
    }

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };
    return (
        <Dialog
            onClose={handleClose} open={open}
        >
            <DialogTitle>
                {title}
            </DialogTitle>
            <List
                sx={{
                    pt: 0
                }}
            >
                {elems.map((item, i) => (
                    <ListItem disableGutters key={i}>
                        <ListItemButton
                            onClick={() => handleListItemClick(item)}
                        >
                            <ListItemAvatar>
                                <Avatar
                                    sx={{
                                        bgcolor: thema.bgcolor,
                                        color: thema.color
                                    }}>
                                    <DescriptionIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={GetPrimary(item)}
                                secondary={GetSecondary(item)}
                            />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Dialog >
    )
}

/**
 * Компонент диалоговое окно с возможностью добавления нового события с этапом эвакуации
 * @param { Object } props передаваемые параметры в компонент
 * @returns компонент
 */
function EventByInsertDlg(props) {
    const { thema, elems, title, selectedValue, open, onClose, } = props;

    const GetPrimary = (item) => {
        return (
            <>
                Время обращения: {getDateTimeView(item.dateOfVisits)}<br />
                Место оказания медицинской помощи: {item.locationMedicalAssistance}
            </>
        )
    }

    const GetSecondary = (item) => {
        return (
            <>
                Диагноз: {item.diagnosis}
            </>
        )
    }

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };

    const handleListItemNewClick = () => {
        onClose(null);
    }

    return (
        <Dialog
            onClose={handleClose} open={open}
        >
            <DialogTitle>
                <Grid
                    container spacing={1}
                >
                    <Grid
                        item
                    >
                        <ManageSearchIcon />
                    </Grid>
                    <Grid
                        item
                    >
                        {title}
                    </Grid>
                </Grid>
            </DialogTitle>
            <List
                sx={{
                    pt: 0
                }}
            >
                <ListItem disableGutters>
                    <ListItemButton
                        autoFocus
                        onClick={() => handleListItemNewClick()}
                    >
                        <ListItemAvatar>
                            <Avatar>
                                <AddIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Добавить историю болезни" />
                    </ListItemButton>
                </ListItem>
                {elems.map((item, i) => (
                    <ListItem disableGutters key={i}>
                        <ListItemButton
                            onClick={() => handleListItemClick(item)}
                        >
                            <ListItemAvatar>
                                <Avatar
                                    sx={{
                                        bgcolor: thema.bgcolor,
                                        color: thema.color
                                    }}>
                                    <DescriptionIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={GetPrimary(item)}
                                secondary={GetSecondary(item)}
                            />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Dialog >
    )
}

/**
 * Компонент диалоговое окно редактирования пользователя системы
 * @param { Object } props передаваемые параметры в компонент
 */
function UserEditDlg(props) {
    const { formik, open, handleClose, handleClick, isUpdate, roles, choices, setChoices, chosen, setChosen } = props;
    
    const model = {
        title: {
            val1: 'Редактирование пользователя',
            val2: 'Добавление пользователя',
        },
        fio: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'fio',
            label: 'Фамилия Имя Отчество',
        },
        mRank: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'mRank',
            label: 'Примечание',
        },
        role:{
            size: 'small',
            thema: fields.collection2,
            variant: 'outlined',
            name: 'role',
            label: 'Полномочия',
        },
        login: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'login',
            label: 'Логин',
        },
        password: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'password',
            label: 'Пароль',
        },
        lic: {
            thema: fields.collection2,
            size: 'small',
            type: 'password',
            variant: 'outlined',
            name: 'lic',
            label: 'Суточный пароль',
        },
        bntCancel: {
            thema: buttons.collection1,
            icon: <UndoOutlinedIcon />,
            variant: 'contained',
            handleClick: handleClose,
            value: 'Отменить',
        },
        btnUpdate: {
            thema: buttons.collection1,
            icon: <SaveAsOutlinedIcon />,
            variant: 'contained',
            handleClick: handleClose,
            value: 'Обновить',
        },
        btnAccept: {
            thema: buttons.collection1,
            icon: <SaveAsOutlinedIcon />,
            variant: 'contained',
            handleClick: handleClose,
            value: 'Сохранить',
        }
    }
    const TitleElem = () => {
        let result = isUpdate ? model.title.val1 : model.title.val2;
        return result;
    }
    const AcceptBtn = () => {
        return isUpdate
            ? <EndIconBtn
                thema={model.btnUpdate.thema} icon={model.btnUpdate.icon}
                variant={model.btnUpdate.variant}
                value={model.btnUpdate.value}
                handleClick={handleClick}
            />
            : <EndIconBtn
                thema={model.btnAccept.thema} icon={model.btnAccept.icon}
                variant={model.btnAccept.variant}
                value={model.btnAccept.value}
                handleClick={handleClick}
            />
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            open={open}
            onClose={handleClose}
            aria-labelledby="modalUserEditDescription"
            aria-describedby="modalUserEditDescription"
        >
            <DialogTitle id="modalUserEditDescriptionTitle">
                <TitleElem />
            </DialogTitle>
            <DialogContent
                sx={{
                    marginTop: '5px'
                }}
            >
                <Grid
                    container
                    spacing={2}
                >
                    <Grid
                        sx={{
                            marginTop: '21px'
                        }}
                        item xs={12} sm={12} md={12} lg={12} xl={12}
                    >
                        <TextFld
                            thema={model.fio.thema} name={model.fio.name}
                            size={model.fio.size} type={model.fio.type}
                            variant={model.fio.variant} label={model.fio.label}
                            value={formik.values.fio}
                            handleBlur={formik.handleBlur} handleChange={formik.handleChange}
                            touched={formik.touched.fio} error={formik.errors.fio}
                        />
                    </Grid>
                    <Grid
                        item xs={12} sm={12} md={12} lg={12} xl={12}
                    >
                        <TextFld
                            thema={model.mRank.thema} name={model.mRank.name}
                            size={model.mRank.size} type={model.mRank.type}
                            variant={model.mRank.variant} label={model.mRank.label}
                            value={formik.values.mRank}
                            handleBlur={formik.handleBlur} handleChange={formik.handleChange}
                            touched={formik.touched.mRank} error={formik.errors.mRank}
                        />
                    </Grid>
                    <Grid
                        item xs={12} sm={12} md={12} lg={12} xl={12}
                    >
                        <SelectCbx 
                            thema={model.role.thema} name={model.role.name}
                            size={model.role.size} variant={model.role.variant} 
                            label={model.role.label}
                            formik={formik}
                            elems={roles}
                        />
                    </Grid>
                    <Grid
                        item xs={12} sm={12} md={12} lg={12} xl={12}
                    >
                        <TextFld
                            thema={model.login.thema} name={model.login.name}
                            size={model.login.size} type={model.login.type}
                            variant={model.login.variant} label={model.login.label}
                            value={formik.values.login}
                            handleBlur={formik.handleBlur} handleChange={formik.handleChange}
                            touched={formik.touched.login} error={formik.errors.login}
                        />
                    </Grid>
                    <Grid
                        item xs={12} sm={12} md={12} lg={12} xl={12}
                    >
                        <PasswordFld
                            thema={model.password.thema} name={model.password.name}
                            size={model.password.size} type={model.password.type}
                            variant={model.password.variant} label={model.password.label}
                            value={formik.values.password}
                            handleBlur={formik.handleBlur} handleChange={formik.handleChange}
                            touched={formik.touched.password} error={formik.errors.password}
                        />
                    </Grid>
                    <Grid
                        item xs={12} sm={12} md={12} lg={12} xl={12}
                    >
                        <PasswordFld
                            thema={model.lic.thema} name={model.lic.name}
                            size={model.lic.size} type={model.lic.type}
                            variant={model.lic.variant} label={model.lic.label}
                            value={formik.values.lic}
                            handleBlur={formik.handleBlur} handleChange={formik.handleChange}
                            touched={formik.touched.lic} error={formik.errors.lic}
                        />
                    </Grid>
                    <Grid
                        item xs={12} sm={12} md={12} lg={12} xl={12}
                    >
                        <SelectAllTransferList 
                            choices={choices} setChoices={setChoices} chosen={chosen} setChosen={setChosen}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions
                sx={{
                    px: '24px',
                    pb: '15px'
                }}
            >
                <Grid
                    container
                >
                    <Grid
                        item xs={5} sm={3} md={3} lg={3} xl={3}
                    >
                        <StartIconBtn
                            thema={model.bntCancel.thema} icon={model.bntCancel.icon}
                            variant={model.bntCancel.variant}
                            value={model.bntCancel.value}
                            handleClick={handleClose}
                        />
                    </Grid>
                    <Grid
                        item xs={2} sm={6} md={6} lg={6} xl={6}
                    />
                    <Grid
                        item xs={5} sm={3} md={3} lg={3} xl={3}
                    >
                        <AcceptBtn />
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

/**
 * Компонент диалоговое окно редактирования лпу
 * @param {object} props передаваемые параметры в компонент
 */
function LpuEditDlg(props) {
    const { formik, open, handleClose, handleClick, isUpdate, isBlockField } = props;

    const model = {
        title: {
            val1: 'Редактирование медицинской организации',
            val2: 'Добавление медицинской организации',
        },
        name: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'name',
            label: 'Наименование направления эвакуации',
        },
        fullName: {
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'fullName',
            label: 'Шапка документа',
        },
        docFooter:{
            thema: fields.collection2,
            size: 'small',
            type: 'text',
            variant: 'outlined',
            name: 'fullName',
            label: 'Подвал документа',
        },
        bntCancel: {
            thema: buttons.collection1,
            icon: <UndoOutlinedIcon />,
            variant: 'contained',
            handleClick: handleClose,
            value: 'Отменить',
        },
        btnUpdate: {
            thema: buttons.collection1,
            icon: <SaveAsOutlinedIcon />,
            variant: 'contained',
            handleClick: handleClose,
            value: 'Обновить',
        },
        btnAccept: {
            thema: buttons.collection1,
            icon: <SaveAsOutlinedIcon />,
            variant: 'contained',
            handleClick: handleClose,
            value: 'Сохранить',
        }
    }
    const TitleElem = () => {
        return isUpdate 
            ? model.title.val1 
            : model.title.val2;
    }
    const AcceptBtn = () => {
        return isUpdate
            ? <EndIconBtn
                thema={model.btnUpdate.thema} icon={model.btnUpdate.icon}
                variant={model.btnUpdate.variant}
                value={model.btnUpdate.value}
                handleClick={handleClick}
            />
            : <EndIconBtn
                thema={model.btnAccept.thema} icon={model.btnAccept.icon}
                variant={model.btnAccept.variant}
                value={model.btnAccept.value}
                handleClick={handleClick}
            />
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            open={open}
            onClose={handleClose}
            aria-labelledby="modalUserEditDescription"
            aria-describedby="modalUserEditDescription"
        >
            <DialogTitle id="modalUserEditDescriptionTitle">
                <TitleElem />
            </DialogTitle>
            <DialogContent
                sx={{
                    marginTop: '5px'
                }}
            >
                <Grid
                    container
                    spacing={2}
                >
                    <Grid
                        sx={{
                            marginTop: '21px'
                        }}
                        item xs={12} sm={12} md={12} lg={12} xl={12}
                    >
                        <TextFld
                            thema={model.name.thema} name={model.name.name}
                            size={model.name.size} type={model.name.type}
                            variant={model.name.variant} label={model.name.label}
                            isDisabled={isBlockField}
                            value={formik.values.name}
                            handleBlur={formik.handleBlur} handleChange={formik.handleChange}
                            touched={formik.touched.name} error={formik.errors.name}
                        />
                    </Grid>
                    <Grid
                        item xs={12} sm={12} md={12} lg={12} xl={12}
                    >
                        <TextFld
                            thema={model.fullName.thema} name={model.fullName.name}
                            size={model.fullName.size} type={model.fullName.type}
                            variant={model.fullName.variant} label={model.fullName.label}
                            value={formik.values.fullName}
                            handleBlur={formik.handleBlur} handleChange={formik.handleChange}
                            touched={formik.touched.fullName} error={formik.errors.fullName}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions
                sx={{
                    px: '24px',
                    pb: '15px'
                }}
            >
                <Grid
                    container
                >
                    <Grid
                        item xs={5} sm={3} md={3} lg={3} xl={3}
                    >
                        <StartIconBtn
                            thema={model.bntCancel.thema} icon={model.bntCancel.icon}
                            variant={model.bntCancel.variant}
                            value={model.bntCancel.value}
                            handleClick={handleClose}
                        />
                    </Grid>
                    <Grid
                        item xs={2} sm={6} md={6} lg={6} xl={6}
                    />
                    <Grid
                        item xs={5} sm={3} md={3} lg={3} xl={3}
                    >
                        <AcceptBtn />
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

export {
    PersonnalDlg, PersonnalByInsertDlg, EventDlg, EventByInsertDlg, UserEditDlg, LpuEditDlg
}