import React, { memo, useEffect, useMemo, useState } from "react";

import { ruRU } from '@mui/x-data-grid-pro/locales';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
    Card,
    Grid,
    Typography,
    Box,
    Button,
    styled,
    CircularProgress,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Alert,
    ListItem,
    Stack,
    LinearProgress,
} from "@mui/material";

import {
    gridClasses,
    DataGridPro,
    useGridApiRef,
} from '@mui/x-data-grid-pro';

import { CustomToolbar } from "./components/CustomToolbar";

import { PrintList } from "./components/PrintList";

import {
    sendGetUploadData,
    selectLoading,
    selectProgressData,
    selectAnalisedData,
    resetUploadData,
    resetAnalysisData,
    sendAnalysisFile,
    sendLoadFile,
    sendLoaderByProgress,
} from "../upload/uploadSlice";

import { fields } from '../../app/thema';


import "./styles.css";
import { appStorage, fireToast } from "../../helpers";
import { urlHistory } from "../../common/helper/UrlRouter";
import { Cancel, Check, CloudUpload, } from "@mui/icons-material";

const LinearProgressWithLabel = ({ value = 0, data = {} }) => {
    let progress = data.progress || "-";
    let recordCount = data.recordCount || "-";
    let showValue = `${Math.round(value)}% (${progress}/${recordCount})`


    return (
        <Box sx={{ display: 'flex', alignItems: 'center', ml: 3, mb: 2 }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" value={value} sx={{ height: 10 }} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {`${showValue}`}
                </Typography>
            </Box>
        </Box>
    );
}

const fixSettings = (settings = {}, apiRef) => {
    let fixedSettings = { ...settings };

    // some fixes for users LS data
    let currentFilterModel = apiRef?.current?.state?.filter?.filterModel?.items || [];
    let columnsLookup = apiRef?.current?.store?.value?.columns?.lookup || {};
    let hasBrokenFilters = false;

    currentFilterModel.forEach(item => {
        let filterOperators = columnsLookup[item.field]?.filterOperators || [];
        let cleanFilterOperators = filterOperators.map(item => item.value) || [];

        if (!cleanFilterOperators.includes(item.operator)) {
            console.log("hasBrokenFilters", cleanFilterOperators, item.operator);
            hasBrokenFilters = true;
        }
    });

    if (hasBrokenFilters) {
        delete fixedSettings.filter.filterModel;
    }

    // Удалаяем пустой объект columnVisibilityModel, иначе будет считаться что нуно показать все колонки
    if (settings.columns && settings.columns.hasOwnProperty("columnVisibilityModel")) {
        if (Object.keys(settings.columns.columnVisibilityModel).length === 0) {
            delete fixedSettings.columns.columnVisibilityModel;
        }
    }
    return fixedSettings;
}


const UploadComponent = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [rows, setRows] = useState([]);
    const [age, setAge] = useState(500);
    const [type, setType] = useState(2);
    const [workingProgress, setWorkingProgress] = useState(false);
    // const [errorsArray, setErrorsArray] = useState([]);

    let intervalId = null;
    let percent = 0;

    const apiRef = useGridApiRef();
    let progressData = useSelector(selectProgressData);
    let analisedData = useSelector(selectAnalisedData) || {};
    let loading = useSelector(selectLoading);

    const [columnVisibilityModel, setColumnVisibilityModel] = useState({});

    let initialRows = useMemo(() => [], []);
    let columns = [];
    let columnsToHide = {};
    let columnsToPrint = [];
    let columnsToGroupChange = [];

    let authentication = appStorage.getItem('authentication') || {};
    let userId = authentication.idPersonnel || "";
    let userSettingsLS = appStorage.getItem(userId) || {};
    let uploadSettingsLS = fixSettings(userSettingsLS.uploadSettings, apiRef) || {};

    const [uploadSettings, setUploadSettings] = useState(uploadSettingsLS);

    let errors1 = "";
    let errors2 = "";
    let errors3 = "";

    if (analisedData.columnsByPatientNotFound?.length || analisedData.columnsByEventNotFound?.length || analisedData.columnsByStageEvacuationNotFound?.length) {
        if (analisedData.columnsByPatientNotFound?.length) {
            errors1 = <>
                Не найдены поля [ошибка columnsByPatientNotFound]:<br />
                <ul>{analisedData.columnsByPatientNotFound.map(item => <li>{item}</li>)}</ul>
            </>;
        }
        if (analisedData.columnsByEventNotFound?.length) {
            errors2 = <>
                Не найдены поля [ошибка columnsByEventNotFound]:<br />
                <ul>{analisedData.columnsByEventNotFound.map(item => <li>{item}</li>)}</ul>
            </>;
        }
        if (analisedData.columnsByStageEvacuationNotFound?.length) {
            errors3 = <>
                Не найдены поля [ошибка columnsByStageEvacuationNotFound]:<br />
                <ul>{analisedData.columnsByStageEvacuationNotFound.map(item => <li>{item}</li>)}</ul>
            </>;
        }

    }



    let analisedTable = {
        columns: [],
        rows: [],
    };

    if (analisedData.importLines?.length) {
        // собираем колонки
        let keys = Object.keys(analisedData.importLines[0]?.value || {});
        keys.forEach(key => {
            analisedTable.columns.push({
                dependencyField: null,
                dependencyValues: null,
                editable: false,
                editableFields: null,
                field: key,
                fieldType: "String",
                flex: undefined,
                groupChange: false,
                headerName: key,
                hidden: false,
                hiddenPermanent: false,
                hideable: false,
                minWidth: undefined,
                renderEditCell: undefined,
                sortable: false,
                toPrint: false,
                type: "string",
                values: null,
                width: undefined,
            });
        });
        //

        analisedData.importLines.forEach(line => {
            let row = line.value || {};

            analisedTable.rows.push({
                h300_id: line.key,
                ...row,
            });

        });
    }

    if (analisedData.table?.rows) {
        analisedTable = analisedData.table;
    }

    console.log(analisedTable);

    if (analisedData.table) {
        initialRows = analisedData.table.rows;
        columns = [...analisedData.table.columns];

        columns.forEach(column => {
            if (column.hidden) {
                columnsToHide[column.field] = false;
            }
            if (column.toPrint) {
                columnsToPrint.push(column.field);
            }
            if (column.groupChange) {
                columnsToGroupChange.push(column.field);
            }
        })
    }

    useEffect(() => {
        dispatch(sendGetUploadData());
    }, [dispatch]);

    useEffect(() => {
        let columnsToHide = {};

        if (analisedData.table) {
            columns.forEach(column => {
                if (column.hidden) {
                    columnsToHide[column.field] = false;
                }
            })
            setColumnVisibilityModel(columnsToHide)

            initialRows = analisedData.table.rows;
            setRows(initialRows);
        }
    }, [analisedData.table]);


    useEffect(() => {
        return () => {
            dispatch(resetUploadData());
        };
    }, []);

    useEffect(() => {
        if (initialRows && apiRef?.current?.restoreState != null && uploadSettings != null && initialRows.length) {
            setTimeout(() => {
                apiRef?.current?.restoreState(fixSettings(uploadSettings, apiRef));
            }, 200);
        }
    }, [apiRef, initialRows, uploadSettings]);


    const handleStateChange = (eventType) => {
        console.log("eventType", eventType);
        let currentState = apiRef.current.exportState();

        let uploadSettings = {
            sorting: currentState.sorting,
            filter: currentState.filter,
            // pinnedColumns: currentState.pinnedColumns,
            columns: {
                columnVisibilityModel: currentState.columns.columnVisibilityModel,
                // orderedFields: currentState.columns.orderedFields,
                // dimensions: currentState.columns.dimensions,
            },
        };


        if (currentState && currentState.columns) {
            appStorage.setItem(userId, {
                ...userSettingsLS,
                "uploadSettings": fixSettings(uploadSettings, apiRef),
            });
        }
    }

    const handleRowClick = (data, rwer, wttgwe) => {
        navigate(`${urlHistory}/${data?.row?.jp_id_personnel}/${data?.row?.e300_id}/${data?.row?.h300_id}`);
    }

    if (apiRef.current?.subscribeEvent) {
        apiRef.current.subscribeEvent("filterModelChange", () => handleStateChange("filterModelChange"));
        apiRef.current.subscribeEvent("columnVisibilityModelChange", () => handleStateChange("columnVisibilityModelChange"));
        apiRef.current.subscribeEvent("sortModelChange", () => handleStateChange("sortModelChange"));
    }






    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const handleChangeType = (event) => {
        setType(event.target.value);
    };

    const handleClickConfirm = () => {
        setWorkingProgress(true);

        intervalId = setInterval(() => {
            dispatch(sendLoaderByProgress());
        }, 3000);

        dispatch(sendLoadFile({
            data: {
                taskGuid: analisedData.taskGuid,
                isTaskDelete: false,
                LoadingMode: type,
            },
            success: () => {
                fireToast({
                    message: "Данные загружены"
                });
                handleClickReset();
                clearInterval(intervalId);
                intervalId = null;
                percent = 0;
                setWorkingProgress(false);
            },
            always: () => {
                clearInterval(intervalId);
                intervalId = null;
                percent = 0;
                setWorkingProgress(false);
            }
        }));
    }

    const handleClickReset = () => {
        dispatch(resetAnalysisData());
    }

    const handleChangeFile = (event) => {
        const files = event.target.files;

        // Проверяем поддержку File API 
        if (window.File && window.FileReader && window.FileList && window.Blob) {
            // Работает
        } else {
            alert('File API не поддерживается данным браузером');
        }

        var file = files[0];

        dispatch(sendAnalysisFile({
            file: file,
            data: { RecordCount: age },
        }));
    }

    let currentProgressData = {};
    if (progressData && progressData.length) {
        currentProgressData = progressData.find(item => item.taskGuid === analisedData.taskGuid) || {};

        let progress = currentProgressData?.progress || 1;
        let recordCount = currentProgressData?.recordCount || 1;

        percent = progress / recordCount * 100;

    } else {
        percent = 0;
        // setWorkingProgress(false);
    }

    console.log(percent);
    return <>
        <Grid
            sx={{
                mt: '15px',
            }}
            container
        >
            <Grid
                item
                xs={12}
                md={12}
                lg={12}
                xl={12}
            >
                <Card
                    sx={{
                        mx: '5px',
                        py: '15px',
                        pl: '10px',
                        pr: '40px',
                        borderRadius: '10px'
                    }}
                    variant="outlined"
                >
                    <Grid
                        container spacing={2}
                    >
                        <Grid
                            style={{
                                textAlign: 'center',
                            }}
                            item
                            xs={12}
                        >
                            <Typography
                                variant="h4"
                                color={fields.collection2.borderColor}
                            >
                                Загрузка данных
                            </Typography>

                            <Box
                                sx={{ m: 4 }}
                            >
                                {!analisedTable.rows?.length ? <>
                                    <FormControl sx={{ mr: 1, mt: "-1px", minWidth: 250 }} size="small">
                                        <InputLabel id="select-small-label">Кол-во записей для предпросмотра</InputLabel>
                                        <Select
                                            labelId="select-small-label"
                                            id="select-small"
                                            value={age}
                                            label="Кол-во записей для предпросмотра"
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={10}>10</MenuItem>
                                            <MenuItem value={100}>100</MenuItem>
                                            <MenuItem value={500}>500</MenuItem>
                                            <MenuItem value={1000}>1000</MenuItem>
                                            <MenuItem value={5000}>5000</MenuItem>
                                            <MenuItem value={10000}>10000</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <Button
                                        disabled={loading}
                                        component="label"
                                        role={undefined}
                                        variant="contained"
                                        tabIndex={-1}
                                        startIcon={<CloudUpload />}
                                    >
                                        Анализировать файл
                                        <VisuallyHiddenInput
                                            type="file"
                                            onChange={handleChangeFile}
                                            multiple
                                        />

                                    </Button>
                                </>
                                    :
                                    <>
                                        <FormControl sx={{ mr: 1, mt: "-1px", minWidth: 250 }} size="small">
                                            <InputLabel id="select-small-label">Тип загрузки</InputLabel>
                                            <Select
                                                labelId="select-small-label"
                                                id="select-small"
                                                value={type}
                                                label="Тип загрузки"
                                                onChange={handleChangeType}
                                            >
                                                <MenuItem value={0}>не указано</MenuItem>
                                                <MenuItem value={1}>Добавление новых записей</MenuItem>
                                                <MenuItem value={2}>Добавление новых записей/заполнение пустых полей</MenuItem>
                                                <MenuItem value={3}>Добавление/обновления записей с заполнением пустых полей</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <Button
                                            disabled={loading}
                                            component="label"
                                            variant="contained"
                                            tabIndex={-1}
                                            startIcon={<Check />}
                                            onClick={handleClickConfirm}
                                        >
                                            Подтвердить загрузку данных
                                        </Button>
                                        &nbsp;
                                        <Button
                                            disabled={loading}
                                            component="label"
                                            variant="outlined"
                                            tabIndex={-1}
                                            startIcon={<Cancel />}
                                            onClick={handleClickReset}
                                        >
                                            Отменить
                                        </Button>
                                    </>
                                }
                            </Box>
                        </Grid>
                    </Grid>

                    {workingProgress && <LinearProgressWithLabel value={percent} data={currentProgressData} />}

                    <Stack direction={"row"}>
                        {errors1 && <Alert severity="error" variant="filled" sx={{ ml: 2 }}>{errors1}</Alert>}
                        {errors2 && <Alert severity="error" variant="filled" sx={{ ml: 2 }} >{errors2}</Alert>}
                        {errors3 && <Alert severity="error" variant="filled" sx={{ ml: 2 }} >{errors3}</Alert>}
                    </Stack>
                    {/* {loading && <Box sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                    }}>
                        <CircularProgress />
                    </Box>} */}


                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            height: "calc(100vh - 250px)",
                            width: '100%',
                            justifyContent: "center",
                            // display: analisedTable.rows?.length ? "flex" : "none",
                        }}
                    >
                        <Box
                            className="upload-table-container"
                            sx={(theme) => ({
                                ml: "50px",
                                height: "calc(100vh - 250px)",
                                width: '100%',
                                '& .MuiDataGrid-cell--editable': {
                                    bgcolor: 'rgba(217, 243, 190, 0.3)',
                                    ...theme.applyStyles('dark', {
                                        bgcolor: '#444',
                                    }),
                                },
                                '& .MuiDataGrid-cell': {
                                    borderLeft: '1px solid lightgray',
                                    // cursor: 'pointer',
                                },
                                '& .MuiDataGrid-cell--editable input, & .MuiDataGrid-cell--editable textarea, & .MuiDataGrid-cell--editable div:nth-of-type(1)': {
                                    backgroundColor: 'rgb(245, 229, 182)',
                                    padding: "0 2px",
                                },
                                '& .MuiDataGrid-cell--editable div:nth-of-type(1)': {
                                    padding: "0 10px 0 2px",
                                    fontSize: "0.875rem",
                                },
                                '& .MuiDataGrid-cell--editable div:nth-of-type(1) [type="datetime-local"] ': {
                                    padding: "0",
                                },
                                '& .MuiDataGrid-columnHeader': {
                                    "& div": {
                                        whiteSpace: "wrap",
                                    }
                                },
                                '& .MuiDataGrid-row.Mui-selected': {
                                    backgroundColor: "rgba(25, 118, 210, 0.4) !important",
                                },
                                '& .MuiDataGrid-row.Mui-selected:hover': {
                                    backgroundColor: "rgba(25, 118, 210, 0.5) !important",
                                },
                                '& .MuiDataGrid-row:hover': {
                                    backgroundColor: "rgba(141, 155, 142, 0.2) !important",
                                },
                            })}>
                            <DataGridPro
                                sx={{
                                    [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
                                        outline: 'none',
                                    },
                                    [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                                    {
                                        outline: 'none',
                                    },
                                    [`& .${gridClasses["row--borderBottom"]}`]:
                                    {
                                        backgroundColor: "transparent !important",
                                    },
                                    [`& .${gridClasses.columnHeaders}`]:
                                    {
                                        backgroundColor: "#bfc4b5",
                                    },
                                    [`& .${gridClasses.columnHeaderTitle}`]:
                                    {
                                        fontWeight: 600,
                                        letterSpacing: "0.05071em",
                                    },
                                }}
                                apiRef={apiRef}
                                getRowId={(row) => row.h300_id}
                                localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                                rows={analisedTable.rows}
                                columns={analisedTable.columns}
                                editMode="row"
                                getRowHeight={() => 'auto'}
                                // onRowDoubleClick={handleRowClick}
                                slots={{
                                    toolbar: CustomToolbar.bind(this, {}),
                                }}
                                slotProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                    },
                                }}
                                // checkboxSelection
                                // disableRowSelectionOnClick
                                loading={loading}
                                columnVisibilityModel={columnVisibilityModel}
                                onColumnVisibilityModelChange={(newModel) =>
                                    setColumnVisibilityModel(newModel)
                                }
                            />
                        </Box>
                    </Box>






















                </Card>
            </Grid>
        </Grid >
    </>;


}


export const Upload = memo(UploadComponent);
