import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    historyChain: [],
};

export const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        setHistoryChain: (state, action) => {
            state.historyChain = action.payload;
        },
        addToHistoryChain: (state, action) => {
            console.log(state, action);
            state.historyChain = [...state.historyChain, action.payload];
        },
        clearHistoryChain: (state) => {
            state.historyChain = initialState.historyChain;
        }
    },
})


export const {
    setHistoryChain,
    addToHistoryChain,
    clearHistoryChain,
} = globalSlice.actions;


export const selectHistoryChain = (state) => state.global.historyChain;

export default globalSlice.reducer;
