import React, { useEffect } from 'react';


import {
    Box, AppBar, Toolbar, IconButton, Typography,
    Button
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import BackIcon from '@mui/icons-material/ArrowBack';
import { useSelector, useDispatch } from 'react-redux';
import { selectHistoryChain, setHistoryChain } from '../app/reducers/globalSlice';
import { useNavigateWithHistory } from '../helpers';
import { useLocation, useNavigationType } from 'react-router-dom';


/**
 * Виджет меню
 * @param { Object } props передаваемые параметры
 * @returns виджет
 */

const BackButton = (props) => {
    return (
        <Button
            variant="contained"
            size='small'
            startIcon={<BackIcon />}
            onClick={props.onClick}
            sx={{
                mr: 2
            }}
        >
            Назад
        </Button>

    )
};
export default function AppBarMenu(props) {
    const historyChain = useSelector(selectHistoryChain);
    const dispatch = useDispatch();
    const navigateWithHistory = useNavigateWithHistory();
    const location = useLocation();
    const navType = useNavigationType();

    const handleClickBack = () => {
        navigateWithHistory('', { action: 'back' });
    }

    useEffect(() => {
        // отлавливаем нажатие кнопки Назад в браузере
        if (navType === "POP" && location.key !== "default") {
            if (historyChain.length && location.pathname !== historyChain[historyChain.length - 1]) {
                let newHistoryChain = historyChain.slice(0, -1);
                dispatch(setHistoryChain(newHistoryChain));
            }
        }
    }, [dispatch, location, navType]);

    const model = {
        appBar: {
            background: '#778D45',
            minHeight: '48px !important',
            flexGrow: 1,
            marginLeft: 'auto',
            position: 'static',
            img: {
                src: '/img/logo128.png',
                marginRight: '1em',
                width: '38px',
            },
            header: {
                alignItems: 'center',
                display: 'flex',
                variant: 'h6',
                component: 'div',
            },
        }
    }

    return (
        <Box
            sx={{
                flexGrow: model.appBar.flexGrow
            }}>
            <AppBar
                position={model.appBar.position}>
                <Toolbar
                    sx={{
                        background: model.appBar.background,
                        minHeight: model.appBar.minHeight,
                    }}>
                    <IconButton
                        sx={{
                            mr: 2
                        }}
                        size="large" edge="start" color="inherit" aria-label="menu"
                        onClick={props.handleClick}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        sx={{
                            alignItems: model.appBar.header.alignItems,
                            display: model.appBar.header.display,
                        }}
                        variant={model.appBar.header.variant} component={model.appBar.header.component}
                    >
                        {historyChain.length ? <BackButton onClick={handleClickBack} /> : null}{props.headerValue}
                    </Typography>
                </Toolbar>
            </AppBar>
        </Box>
    )
}
