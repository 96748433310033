// REST API Контроллер взаимодействия с формами
import {
    ResponseCheck, PostFetchAsync, PostFetchFileAsync
} from '../api/QueryHandler';
import {
    GetExportPOST, GetMMOListPOST
} from '../helper/UrlController';
import {
    ResponseAlert
} from '../models/Response';
import {
    RequestMMOList
} from '../models/Request';


/**
 * API для получения формы 300
 * @param { String } idPersonnel УИН записи журнала личного состава
 */
async function GetExportAsync(data) {
    let model = {
        alert: {},
        result: {}
    };
    try {

        let res = await PostFetchFileAsync(GetExportPOST().url, data);
        // Валидация
        // if (!ResponseCheck(res)) {
        //     return res;
        // }
        // model.alert = new ResponseAlert(res.alert.code, res.alert.actionCode, res.alert.title, res.alert.message, res.alert.level, 
        //     res.alert.sticky
        // ).getModel();
        // model.result = new ItemForma300(
        //     res.result.idEvent, res.result.dateOfInjury, res.result.locationWhereInjury, res.result.circumstances,
        //     res.result.nature, res.result.gravityOfInjury, res.result.idHistory, res.result.stateByEvacuation,
        //     res.result.locationMedicalAssistance, res.result.dateOfVisits, res.result.diagnosis, res.result.helpProvided, 
        //     res.result.recommendations, res.result.locationWhere, res.result.dateOfEvacuation
        // ).getModel();
    } catch (err) {
        console.log(err);
        // model.alert = new ResponseAlert().getCode500();
    }

    return model;
}
/**
 * 
 * @param { String } data УИН записи журнала личного состава
 * @returns 
 */
async function GetMMOListAsync() {
    let model = {
        alert: {},
        result: {}
    };
    try {
        let req = new RequestMMOList().getModel();
        let res = await PostFetchAsync(GetMMOListPOST().url, req);
        // Валидация
        if (!ResponseCheck(res)) {
            return res;
        }

        model = res;
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}


export {
    GetExportAsync, GetMMOListAsync
}