import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import { useFormik } from 'formik';
import { useNavigate } from "react-router-dom";
import { appStorage } from '../helpers';
import { urlManagement } from '../common/helper/UrlRouter';

import { fields, buttons, cards } from '../app/thema';
import { alertSuccess, alertWarn, alertError, alertInfo } from '../common/helper/ToastAlert';
import { IsCode200, IsCode503 } from '../common/api/QueryHandler';
import { GetUsersAsync, SetUserAsync } from '../common/api/Management';
import { GetMMOListAsync } from '../common/api/Export';
import roleJson from '../shared/roles.json';

import { Grid, Card, Typography } from '@mui/material';
import { UserCard } from '../components/Cards';
import { UserEditDlg } from '../components/Dialogs';
import { TextFld, PasswordFld } from '../components/Fields';
import { StartIconBtn, EndIconBtn, IconBtn } from '../components/Buttons';

import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined';
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
import PersonAddAltOutlineIcon from '@mui/icons-material/PersonAddAlt';
import VpnKeyIcon from '@mui/icons-material/VpnKey';


/**
 * 
 * @returns 
 */
export default function FormaByUsers() {
    const navigate = useNavigate();
    const [cache, setCache] = useState(appStorage.getItem('authentication'));
    const [userCard, setUserCard] = useState([]);
    const [lpuList, setLPUList] = useState([]);
    const [choices, setChoices] = useState([]);
    const [chosen, setChosen] = useState([]);
    const [open, setOpen] = useState(false);
    const [isUpdate, setIsUpdate] = useState(true);

    // Загрузка данных
    const handleDataLoader = async () => {
        let resUser = await GetUsersAsync();
        let resMMO = await GetMMOListAsync();
        // Валидация запроса
        if (!IsCode200(resUser) && IsCode200(resMMO)) {
            if (IsCode503(resUser)) {
                alertError('Сервис недоступен!');
                setOpen(false);

                return;
            };

            alertWarn('Не удалось создать запись!');

            return;
        };

        let users = [];
        let lpu = resMMO.identified;
        setLPUList(lpu);
        resUser.users.map((elem) => {
            let rl = roleJson.find(e => e.id === elem.role);
            if (cache.role != 'admin' && rl.id === 1) {
                return;
            };

            let bufMMO = resUser.userMMO[`${elem.id}`];
            bufMMO = bufMMO === undefined ? [] : bufMMO;
            let userLpu = [];
            bufMMO.map((mmo) => {
                let buf = lpu.find(e => e.id === mmo);
                if (buf === undefined || buf === null) return;
                userLpu.push({
                    id: buf.id,
                    name: buf.name,
                });
            });

            users.push({
                key: elem.id,
                idPersonnel: elem.idPersonnel,
                login: elem.login,
                password: elem.password,
                lic: elem.key,
                role: rl,
                mRank: elem.mRankS,
                authError: elem.authError,
                isBlock: elem.blocked,
                title: elem.fio,
                tech: elem.tech,
                userMMO: userLpu,
            });
        });
        setUserCard(users);
    };
    const handleClickAccept = () => {
        let rl = roleJson.find(e => e.id === 5);

        formik.values.fio = '';
        formik.values.login = '';
        formik.values.password = '';
        formik.values.mRank = '';
        formik.values.lic = '';
        formik.role = rl;

        let lpu = lpuList.map((elem) => { return elem.name });

        setChoices(lpu);
        setChosen([]);
        setIsUpdate(false);
        setOpen(true);
    };
    const handleClickUpdate = (id) => {
        let elem = userCard.find(e => e.key === id);
        
        formik.values.id = id;
        formik.values.fio = elem.title;
        formik.values.login = elem.login;
        formik.values.password = elem.password;
        formik.values.mRank = elem.mRank;
        formik.values.lic = elem.lic;
        formik.values.role = elem.role;

        let lpu = [];
        let mmo = elem.userMMO.map((elem) => { return elem.name });
        lpuList.forEach((elem) => {
            let buf = mmo.find(e => e === elem.name);
            if (buf === undefined) {
                lpu.push(elem.name);
            }
        });

        setChoices(lpu);
        setChosen(mmo);
        setIsUpdate(true);
        setOpen(true);
    };

    const handleClickSave = () => {
        isUpdate
            ? handleClickUpdateSave()
            : handleClickAcceptSave();
    };
    const handleClickAcceptSave = async () => {
        let userMMO = [];
        chosen.forEach((elem) => {
            let tmp = lpuList.find(e => e.name === elem);
            if (tmp === undefined) return;

            userMMO.push(tmp.id);
        });
        let req = {
            id: uuidv4(),
            idPersonnel: '00000000-0000-0000-0000-000000000000',
            login: formik.values.login,
            password: formik.values.password,
            userKey: formik.values.lic,
            role: formik.values.role,
            mRankS: formik.values.mRank,
            authError: null,
            blocked: false,
            fio: formik.values.fio,
            tech: null,
            mmo: userMMO,
        };
        let res = await SetUserAsync(req);
        // Валидация запроса
        if (!IsCode200(res)) {
            if (IsCode503(res)) {
                alertError('Сервис недоступен!');
                setOpen(false);

                return;
            };

            alertWarn('Не удалось создать запись!');
            setOpen(false);

            return;
        };

        setOpen(false);
        alertSuccess('Запись успешно добавлена!');
        handleDataLoader();
    };
    const handleClickUpdateSave = async () => {
        let elem = userCard.find(e => e.key == formik.values.id);
        let userMMO = [];
        chosen.forEach((elem) => {
            let tmp = lpuList.find(e => e.name === elem);
            if (tmp === undefined) return;

            userMMO.push(tmp.id);
        });

        let req = {
            id: elem.key,
            idPersonnel: elem.idPersonnel,
            login: formik.values.login,
            password: elem.password !== formik.values.password 
                ? formik.values.password 
                : null, // если не меняю то null
            userKey: elem.lic,
            role: elem.role.id, // число в роль
            mRankS: elem.mRank,
            authError: elem.authError,
            blocked: elem.isBlock,
            fio: elem.title,
            tech: elem.tech,
            mmo: userMMO,          
        };
        let res = await SetUserAsync(req);
        // Валидация запроса
        if (!IsCode200(res)) {
            if (IsCode503(res)) {
                alertError('Сервис недоступен!');
                setOpen(false);

                return;
            };

            alertWarn('Не удалось обновить запись!');
            setOpen(false);

            return;
        };

        setOpen(false);
        alertSuccess('Запись успешно добавлена!');
        handleDataLoader();
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleUndoOut = () => {
        navigate(urlManagement);
    };

    const handleFormAction = (values) => {
    };

    useEffect(() => {
        handleDataLoader();
    }, []);

    const model = {
        header: {
            thema: fields.collection2,
            label: 'Пользователи системы',
        },
        cardItem: {
            thema: cards.collection1,
            fontSize: '16pt',
            img: '../img/MilitaryUser.jpg',
            title: '',
            href: '',
        },
        undoBtn: {
            thema: buttons.collection1,
            icon: <UndoOutlinedIcon />,
        },
        keyBtn: {
            thema: buttons.collection1,
            icon: <VpnKeyIcon />
        },
        addBtn: {
            thema: buttons.collection1,
            icon: <PersonAddAltOutlineIcon />
        }
    }
    const context = {
        id: '',
        fio: '',
        mRank: '',
        login: '',
        password: '',
        lic: '',
        role: '',
    }
    const validationSchema = Yup.object().shape({
    });

    const formik = useFormik({
        onSubmit: handleFormAction,
        validationSchema: validationSchema,
        initialValues: context
    });

    // Формирование карточки пользователя
    const CardItem = (props) => {
        const { title, mRank, role, login, isBlock, id } = props;

        const isBlockField = cache.idPersonnel === id;

        return (
            <Grid
                item xs={12} sm={6} md={4} lg={3} xl={3}
            >
                <UserCard
                    thema={model.cardItem.thema} fontSize={model.cardItem.fontSize} img={model.cardItem.img}
                    href={model.cardItem.href} title={title} mRank={mRank} role={role} login={login}
                    isDisabled={true} isBlockField={isBlockField}
                    block={isBlock} handleClick={() => handleClickUpdate(id)}
                />
            </Grid>
        )
    }

    return (
        <>
            <Grid
                sx={{
                    mt: '5px',
                    px: '10px',
                }}
                spacing={2}
                container
            >
                <Grid
                    item xs={12} sm={12} md={12} lg={12} xl={12}
                >
                    <Card
                        sx={{
                            py: '15px',
                            borderRadius: '10px'
                        }}
                        variant="outlined"
                    >
                        <Grid
                            container
                        >
                            <Grid
                                sx={{
                                    pl: '15px',
                                    textAlign: 'center'
                                }}
                                item xs={1} sm={1} ms={1} lg={1} xl={1}
                            >
                                <IconBtn
                                    thema={model.undoBtn.thema} icon={model.undoBtn.icon} handleClick={handleUndoOut}
                                />
                            </Grid>
                            <Grid
                                sx={{
                                    textAlign: 'center',
                                    alignContent: 'center',
                                }}
                                item xs={9} sm={9} md={9} lg={9} xl={9}
                            >
                                <Typography
                                    variant="h4" color={model.header.thema.borderColor}
                                >
                                    {model.header.label}
                                </Typography>
                            </Grid>
                            <Grid
                                sx={{
                                    textAlign: 'right',
                                }}
                                item xs={1} sm={1} ms={1} lg={1} xl={1}
                            >
                                {/*<IconBtn
                                    thema={model.keyBtn.thema} icon={model.keyBtn.icon}
                                />*/}
                            </Grid>
                            <Grid
                                sx={{
                                    pr: '15px',
                                    textAlign: 'center'
                                }}
                                item xs={1} sm={1} ms={1} lg={1} xl={1}
                            >
                                <IconBtn
                                    thema={model.addBtn.thema} icon={model.addBtn.icon} handleClick={handleClickAccept}
                                />
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                {userCard.map((elem) => {
                    return (
                        <CardItem
                            block={elem.isBlock} formik={formik}
                            key={elem.key} id={elem.key} title={elem.title} mRank={elem.mRank} role={elem.role.label}
                            login={elem.login}
                        />
                    );
                })}
            </Grid>
            <UserEditDlg
                formik={formik} open={open} isUpdate={isUpdate}
                roles={roleJson} choices={choices} setChoices={setChoices} chosen={chosen} setChosen={setChosen}
                handleClose={handleClose} handleClick={handleClickSave}
            />
        </>
    )
}