import React from 'react';

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchSendSaveSelected,
  fetchSendSaveRow,
  fetchSendGetLossesData,
  fetchSendSavePatientsLossesStamp,
} from "./lossesAPI";
import {
  getGridSingleSelectOperators,
  getGridDateOperators,
  GridEditSingleSelectCell,
  useGridApiContext,
} from "@mui/x-data-grid-pro";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Input,
  FormHelperText,
  Autocomplete,
} from "@mui/material";
import {
  DatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers-pro';

import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import dayjs from 'dayjs';

// import { appStorage } from "../../helpers";


const initialState = {
  currentPatientId: null,
  currentHistoryItem: {},
  printList: [],
  patientEdit: {
    disease: [],
    lifesAnamnez: [],
  },
  patientData: {
    patientInfo: {},
    losses: [],
  },
  lossesData: {
    table: {
      columns: [],
      rows: [],
    },
  },
  loading: {
    lossesData: false,
  }
};
function CustomFilterSelect(props) {
  const { item, applyValue } = props;

  const apiRef = useGridApiContext();
  let field = props?.item?.field || "";
  let dataRowIdToModelLookup = apiRef.current?.state?.rows?.dataRowIdToModelLookup || {};
  let filteredRowsLookup = apiRef.current?.state?.filter?.filteredRowsLookup || {};
  let filteredIds = Object.keys(filteredRowsLookup).filter(item => filteredRowsLookup[item]);

  let names = filteredIds.map(item => dataRowIdToModelLookup[item]?.[field]);
  names = [...new Set(names)];

  const handleFilterChange = (_, newValue) => {
    applyValue({ ...item, value: newValue });
  };

  return (
    <Autocomplete
      id="free-solo"
      getOptionLabel={(option) => option}
      value={item.value}
      onChange={handleFilterChange}
      options={names.map((option) => option)}
      renderInput={(params) => <TextField {...params} label="Значение" variant='standard' />}
    />
  );
}

function CustomFilterDate(props) {
  const { item, applyValue, focusElementRef } = props;

  const ratingRef = React.useRef(null);
  React.useImperativeHandle(focusElementRef, () => ({
    focus: () => {
      ratingRef.current
        .querySelector(`input[value="${Number(item.value) || ''}"]`)
        .focus();
    },
  }));

  const handleFilterChange = (event, newValue) => {
    console.log(event, newValue);
    applyValue({ ...item, value: event.target.value });
  };

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 48,
        pl: '20px',
      }}
    >

      <FormControl fullWidth>
        <FormControl>
          <InputLabel htmlFor="my-input"></InputLabel>
          <Input
            type='date'
            value={item.value || ""}
            onChange={handleFilterChange}
            ref={ratingRef}
            id="my-input"
            variant='standard'
          />
        </FormControl>
      </FormControl>
    </Box>
  );
}

export const sendSavePatientsLossesStamp = createAsyncThunk(
  "losses/sendSavePatientsLossesStamp",
  async (props = {}, { getState }) => {
    const {
      data = {},
      success = () => { }
    } = props;

    const response = await fetchSendSavePatientsLossesStamp({
      data,
      success
    });

    return response;
  }
);


export const sendGetLossesData = createAsyncThunk(
  "losses/sendGetLossesData",
  async (props = {}, { getState }) => {
    const {
      success = () => { }
    } = props;

    const response = await fetchSendGetLossesData({
      data: {
      },
      success
    });

    return response;
  }
);

export const sendSaveSelected = createAsyncThunk(
  "losses/sendSaveSelected",
  async (props = {}, { getState }) => {
    const {
      selected,
      success = () => { }
    } = props;

    const response = await fetchSendSaveSelected({
      data: {
        guids: selected
      },
      success
    });

    return response;
  }
);

export const sendSaveRow = createAsyncThunk(
  "losses/sendSaveRow",
  async (props = {}, { getState }) => {
    const {
      row,
      success = () => { }
    } = props;
    // const { auth, history } = getState();

    const response = await fetchSendSaveRow({
      data: {
        row
      },
      success
    });

    return response;
  }
);



export const lossesSlice = createSlice({
  name: "losses",
  initialState,
  reducers: {

    resetLossesData: (state, action) => {
      state.lossesData = initialState.lossesData;
    },

    setPrintList: (state, action) => {
      state.printList = action.payload || initialState.printList;
    },

  },

  extraReducers: (builder) => {
    builder


      .addCase(sendGetLossesData.pending, (state) => {
        state.status = "loading";
        state.loading.lossesData = true;
      })

      .addCase(sendGetLossesData.fulfilled, (state, action) => {
        state.status = "idle";
        state.loading.lossesData = false;

        console.log(action.payload);

        let responseData = {};
        let responseRows = action.payload?.rows || {};
        let responseColumns = action.payload?.columns || [];


        console.log(responseColumns);

        const fieldTypes = {
          Guid: "string",
          String: "string",
          DateTimeOffset: "dateTime",
          Date: "date",

          // не используются
          number: "number",
          // date: "date",
          // dateTime: "dateTime",
          Boolean: "boolean",
          singleSelect: "singleSelect",
        }

        function CustomTypeEditComponent(props) {
          // console.log(props);
          const apiRef = useGridApiContext();


          const handleValueChange = async (event) => {

            // console.log(newRow);

            let currentValue = await apiRef.current.getCellValue(props.id, props.dependablefield);
            let currentParams = await apiRef.current.getCellParams(props.id, props.dependablefield);
            let currentDependencyValues = currentParams.colDef.dependencyValues || {};
            let currentListObject = currentDependencyValues[event.target.value] || currentParams.colDef.values;

            // В новом списке не может быть текущего значения, тогда очищаем
            if (!currentListObject.includes(currentValue)) {
              await apiRef.current.setEditCellValue({
                id: props.id,
                field: props.dependablefield,
                value: '',
              });
            }
          };

          return <GridEditSingleSelectCell onValueChange={handleValueChange} {...props} />;
        }

        let widths = {
          count: 10,
          fio: 180,
          jp_military_unit: 70,
          h300_losses_type: 70,
          h300_hospital_ward: 60,
          h300_referral: 90,
          h300_date_action2: 175,
        };


        /**
         * @shitfix
         */
        responseColumns = responseColumns.map(column => {
          if (column.field === "h300_status") {

            let values = column.values || [];
            let depValues = values.map(value => {
              if (value === "Выписан") {
                return "Окончен амбулаторный прием";
              } else if (value === "Находится на лечении") {
                return "Ведется амбулаторный прием";
              } else {
                return value;
              }
            });

            return {
              ...column,
              dependencyField: "h300_stay_mode",
              dependencyValues: { "Амбулаторно": depValues }
            }
          } else {
            return column;
          }
        });
        let flexs = {
          h300_status: 1,
        };
        let minWidths = {
          h300_status: 150,
        };
        responseColumns = responseColumns.map(column => {
          let type = column.fieldType;
          let valueOptions = [];

          let width;
          let flex;
          let minWidth;

          if (column.values && column.values.length) {
            type = "singleSelect";
            valueOptions = column.values;
            // valueOptions = column.values && column.values.length && !column.values.includes("") && !column.values.includes("-") ? ["", ...column.values] : column.values;
          }
          if (flexs[column.field]) {
            flex = flexs[column.field];
          }
          if (widths[column.field]) {
            width = widths[column.field];
          }
          if (minWidths[column.field]) {
            minWidth = minWidths[column.field];
          }



          /**
           * >>>>>>
           */


          let h300_notes = responseRows && responseRows.length ? responseRows.map(row => row.h300_notes) : [];
          h300_notes = [...new Set(h300_notes)];

          if (column.field === 'h300_notes') {
            return {
              ...column,
              filterOperators: getGridSingleSelectOperators()
                .map((operator) => ({
                  ...operator,
                  InputComponent: operator.InputComponent
                    ? (props) => CustomFilterSelect(props, h300_notes)
                    : undefined,
                })),
            };
          }

          if (column.fieldType === 'DateTimeOffset') {
            return {
              width,
              ...column,
              hideable: !column.hiddenPermanent,
              type: fieldTypes[type] || "string",
              filterOperators: getGridDateOperators()
                .map((operator) => {

                  // заменяем "меньше чем", на кастомный, потому что в нативном баг 
                  if (operator.value !== "before") {
                    return operator;
                  } else {
                    return {
                      ...operator,
                      getApplyFilterFn: (filterItem, column) => {
                        console.log(filterItem.field, filterItem.value, filterItem.operator)
                        if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                          return null;
                        }

                        return (value, row, column, apiRef) => {
                          if (!dayjs(value).isValid() || !dayjs(filterItem.value).isValid()) {
                            return true;
                          }

                          let date1 = new Date(value).getTime();
                          let date2 = new Date(filterItem.value).getTime();

                          return date1 < date2;
                        };
                      },
                    }
                  }
                }),
              valueFormatter: (value) => {
                if (column.fieldType === "DateTimeOffset") {
                  return value && new Date(value).toLocaleString(undefined, { timeStyle: "short", dateStyle: "short", });
                }
                if (column.fieldType === "Date") {
                  return value && new Date(value).toLocaleDateString(undefined, {

                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  });
                }
                return value;
              },
            };

          }


          /**
           * <<<<<<
           */
          let filterOperators = {};
          if (type === "singleSelect") {
            filterOperators = {
              filterOperators: getGridSingleSelectOperators()
                .map((operator) => ({
                  ...operator,
                  InputComponent: operator.InputComponent
                    ? (props) => CustomFilterSelect(props)
                    : undefined,
                })),
            };
          }
          // console.log(column);
          return {
            width,
            flex,
            minWidth,
            ...column,
            hideable: !column.hiddenPermanent,
            type: fieldTypes[type] || "string",
            // valueGetter: column.fieldType === "DateTimeOffset" || column.fieldType === "Date" ? (value) => value && new Date(value) : null,
            valueFormatter: (value) => {
              if (column.fieldType === "DateTimeOffset") {
                return value && new Date(value).toLocaleString(undefined, { timeStyle: "short", dateStyle: "short", });
              }
              if (column.fieldType === "Date") {
                return value && new Date(value).toLocaleDateString(undefined, {

                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                });
              }
              return value;
            },
            // cellClassName: () => column.editable ? "editable-cell" : "",
            valueOptions: ({ field, id, row }) => {

              // Предполагаем, что это выпадающий список в фильтре с зависимыми полями. Собираем туда все варианты, которые могут быть.
              if (column?.dependencyField && (!id && !row)) {
                let column = responseColumns.find(column => column.field === field) || {};
                let dependencyValues = column.dependencyValues || {};
                let allDependencyOptions = [...column.values];
                let entries = Object.values(dependencyValues);

                entries.forEach(entry => {
                  allDependencyOptions = [...entry, ...allDependencyOptions];
                });

                allDependencyOptions = [...new Set(allDependencyOptions)]; // оставляем уникальные значения
                allDependencyOptions = allDependencyOptions.filter(option => option !== ""); // пустое удаляем, т.к. движок добавит своё пустое

                return allDependencyOptions.length ? allDependencyOptions : valueOptions;
              }

              if (column.dependencyField) {
                let dependencyValue = row ? row[column.dependencyField] : "";

                if (dependencyValue) {
                  return column.dependencyValues[dependencyValue] || valueOptions;
                } else {
                  return [];
                }

                // console.log(row);
                // console.log(column.dependencyField);
                // console.log(row[column.dependencyField]);
                // console.log(column.dependencyValues);
                // console.log(column.dependencyValues[dependencyValue]);
              }

              return valueOptions;
            },
            renderEditCell: column.field === "h300_status" ? (params) => <CustomTypeEditComponent {...params} dependablefield={"h300_location2"} /> : undefined,
            ...filterOperators,
          }
        });


        console.log(responseColumns);

        responseColumns.unshift({
          dependencyField: null,
          disableColumnMenu: true,
          dependencyValues: null,
          editable: false,
          editableFields: null,
          field: "count",
          fieldType: "String",
          groupChange: false,
          headerName: "№",
          hidden: false,
          sortable: false,
          toPrint: true,
          type: "string",
          width: 40,
          disableReorder: true,
          // valueGetter: (value, row) => { return i++; console.log(value, row) },
          renderCell: (value, row) => {
            let index = value.api.getRowIndexRelativeToVisibleRows(value.id) || 0;

            /**
             * @todo попробовать достать отсортированный список и наложить на него отфильтрованный список (его непонятно где взять)
             */
            // let index = value.api.state.sorting.sortedRows.findIndex((x)=>x===value.id);
            return index + 1;
          },
          values: null,
        })






        /**
         * @shitfix
         */
        responseRows = responseRows.map(row => {
          if (row.h300_stay_mode === "Амбулаторно" && row.h300_status === "Выписан") {
            return {
              ...row,
              h300_status: "Окончен амбулаторный прием",
            }
          }
          if (row.h300_stay_mode === "Амбулаторно" && row.h300_status === "Находится на лечении") {
            return {
              ...row,
              h300_status: "Ведется амбулаторный прием",
            }
          }
          return row;
        });





        responseData = {
          "table": {
            "columns": responseColumns,
            "rows": responseRows,
          }
        };

        // responseRows.length = 10;

        console.log(responseData);


        state.lossesData = responseData;
      })

      .addCase(sendGetLossesData.rejected, (state, action) => {
        state.status = "idle";
        state.loading.lossesData = false;
      })



      .addCase(sendSaveSelected.pending, (state) => {
        state.status = "loading";
        state.loading.lossesData = true;
      })

      .addCase(sendSaveSelected.fulfilled, (state, action) => {
        state.status = "idle";
        state.loading.lossesData = false;

        state.lossesData.table.rows = state.lossesData?.table?.rows?.map(item => {
          return {
            ...item,
            h300_status: action.meta?.arg?.selected?.includes(item.h300_id) ? "Эвакуирован" : item.h300_status
          }
        })
      })

      .addCase(sendSaveSelected.rejected, (state, action) => {
        state.status = "idle";
        state.loading.lossesData = false;
      })



      .addCase(sendSavePatientsLossesStamp.pending, (state) => {
        state.status = "loading";
        state.loading.lossesData = true;
      })

      .addCase(sendSavePatientsLossesStamp.fulfilled, (state, action) => {
        state.status = "idle";
        state.loading.lossesData = false;

        // console.log(action.payload);
        // let oldRows = state.lossesData?.table?.rows || [];

        // let updatedLossesData = oldRows.map(row => {



        //   let newData = action.meta?.arg?.data?.h300_id?.includes(row.h300_id) ? action.payload : {};

        //   let { alert, h300_id, ...cleanData } = newData;

        //   if (action.meta?.arg?.data?.h300_id?.includes(row.h300_id)) {
        //     console.log(action.meta);
        //     console.log(action.meta?.arg?.data?.h300_id);
        //     console.log(newData);
        //     console.log(cleanData);
        //     console.log({
        //       ...row,
        //       ...newData,
        //     });
        //   }
        //   return {
        //     ...row,
        //     ...newData,
        //   }
        // });

        // console.log(updatedLossesData);

        // state.lossesData = {
        //   table: {
        //     ...state.lossesData.table,
        //     rows: updatedLossesData,
        //   }
        // };

      })

      .addCase(sendSavePatientsLossesStamp.rejected, (state, action) => {
        state.status = "idle";
        state.loading.lossesData = false;
      })

      ;
  },
});

export const {
  resetLossesData,
  setPrintList,
} = lossesSlice.actions;


export const selectLossesData = (state) => state.losses.lossesData;
export const selectLoading = (state) => state.losses.loading.lossesData;
export const selectPrintList = (state) => state.losses.printList;

export default lossesSlice.reducer;
