import React, { memo, useEffect, useMemo, useState } from "react";

import { ruRU } from '@mui/x-data-grid-pro/locales';
import { useDispatch, useSelector } from "react-redux";

import { buttons } from '../../app/thema';

import dayjs from 'dayjs';
import {
    Card,
    Grid,
    Typography,
    ListItemIcon,
    Box,
    NativeSelect,
    ListItemText,
    Menu,
    MenuList,
    MenuItem,
    Dialog,
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    Checkbox,
} from "@mui/material";

import { alertError } from '../../common/helper/ToastAlert';
import {
    gridClasses,
    GridRowModes,
    DataGridPro,
    GridActionsCellItem,
    GridRowEditStopReasons,
    useGridApiRef,
    GridCellEditStopReasons,
} from '@mui/x-data-grid-pro';


import PrintIcon from '@mui/icons-material/Print';
import ArrowRightIcon from '@mui/icons-material/Assignment';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';

import { ModalTextField } from "./components/ModalTextField";
import { EditTextarea } from "./components/EditTextarea";
import { ModalStack } from "./components/ModalStack";
import { CustomToolbar } from "./components/CustomToolbar";
import { StartIconBtn, EndIconBtn, IconBtn } from '../../components/Buttons';

import { PrintTpl_1 as PrintTpl1 } from "../history/components/PrintTpl_1";
import { PrintTpl_2 as PrintTpl2 } from "../history/components/PrintTpl_2";
import { PrintTpl_3 as PrintTpl3 } from "../history/components/PrintTpl_3";
import { PrintTpl_4 as PrintTpl4 } from "../history/components/PrintTpl_4";
import { PrintTpl_5 as PrintTpl5 } from "../history/components/PrintTpl_5";
import { PrintTpl_6 as PrintTpl6 } from "../history/components/PrintTpl_6";

import { PrintList } from "./components/PrintList";

import {
    sendGetLossesData,
    sendSaveSelected,
    sendSaveRow,
    selectLossesData,
    selectLoading,
    resetLossesData,
} from "../losses/lossesSlice";


import {
    sendGetPatientData,
    selectPatientData,
} from "../history/historySlice";

import { fields } from '../../app/thema';



import "./styles.css";
import { appStorage, useNavigateWithHistory } from "../../helpers";
import { selectHistoryChain } from '../../app/reducers/globalSlice';
import { useNavigate } from "react-router-dom";
import { urlHistory, urlPersonnel } from "../../common/helper/UrlRouter";

const fixSettings = (settings = {}, apiRef) => {
    let fixedSettings = { ...settings };

    // some fixes for users LS data
    let currentFilterModel = apiRef?.current?.state?.filter?.filterModel?.items || [];
    let columnsLookup = apiRef?.current?.store?.value?.columns?.lookup || {};
    let hasBrokenFilters = false;

    currentFilterModel.forEach(item => {
        let filterOperators = columnsLookup[item.field]?.filterOperators || [];
        let cleanFilterOperators = filterOperators.map(item => item.value) || [];

        if (!cleanFilterOperators.includes(item.operator)) {
            console.log("hasBrokenFilters", cleanFilterOperators, item.operator);
            hasBrokenFilters = true;
        }
    });

    if (hasBrokenFilters) {
        delete fixedSettings.filter.filterModel;
    }

    // Удалаяем пустой объект columnVisibilityModel, иначе будет считаться что нуно показать все колонки
    if (settings.columns && settings.columns.hasOwnProperty("columnVisibilityModel")) {
        if (Object.keys(settings.columns.columnVisibilityModel).length === 0) {
            delete fixedSettings.columns.columnVisibilityModel;
        }
    }
    return fixedSettings;
}

function isKeyboardEvent(event) {
    return !!event.key;
}

const LossesComponent = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const navigateWithHistory = useNavigateWithHistory();
    const historyChain = useSelector(selectHistoryChain);
    const [rows, setRows] = useState([]);
    // const [fieldsToPrint, setFieldsToPrint] = useState([]);
    // const [openPrint, setOpenPrint] = useState(false);
    const [tplId, setTplId] = useState(0);
    const [printData, setPrintData] = useState({});
    const [openModal, setOpenModal] = useState(false);
    const [modalData, setModalData] = useState({});
    const patientData = useSelector(selectPatientData);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const apiRef = useGridApiRef();
    const [rowModesModel, setRowModesModel] = useState({});
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    let lossesData = useSelector(selectLossesData);
    let loading = useSelector(selectLoading);

    const [columnVisibilityModel, setColumnVisibilityModel] = useState({});

    const openPrint = Boolean(anchorEl);

    let initialRows = useMemo(() => [], []);
    let columns = [];
    let columnsToHide = {};
    let columnsToPrint = [];
    let columnsToGroupChange = [];

    let authentication = appStorage.getItem('authentication') || {};
    let userId = authentication.idPersonnel || "";
    let userSettingsLS = appStorage.getItem(userId) || {};
    let lossesSettingsLS = fixSettings(userSettingsLS.lossesSettings, apiRef) || {};

    const [lossesSettings, setLossesSettings] = useState(lossesSettingsLS);


    if (lossesData.table) {
        initialRows = lossesData.table.rows;
        columns = [...lossesData.table.columns];

        columns.forEach(column => {
            if (column.hidden) {
                columnsToHide[column.field] = false;
            }
            if (column.toPrint) {
                columnsToPrint.push(column.field);
            }
            if (column.groupChange) {
                columnsToGroupChange.push(column.field);
            }
        })
    }

    const handleRowEditStop = (params, event) => {
        const field = params.field || "";
        const columns = params.columns || [];
        const column = columns.find(column => column.field === field) || {};
        const renderEditCellName = column?.renderEditCell?.name || "";

        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }

        if (params.reason !== GridCellEditStopReasons.enterKeyDown) {
            return;
        }

        /**
         * @description Если renderEditCellName пустой, значит это кастомное, многострочное поле. Во всех полях кроме недо сохраняем по enter. В многострочном сохраняем по ctrl+enter
         * @todo задать кастомному полю конкретное имя и проверять по нему
         */
        if (!renderEditCellName && isKeyboardEvent(event) && !event.ctrlKey && !event.metaKey) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id) => () => {
        const newRow = apiRef.current.getRowWithUpdatedValues(id);

        // Проверяем заполнены ли нужные поля
        if (newRow.h300_status && newRow.h300_status.toLowerCase() === "ожидает эвакуации") {

            let fields = [
                // "h300_diagnosis",
                // "h300_hospital_ward",
                "h300_diagnosis_final",
                "h300_losses_type",
                "h300_diagnosis_specification",
                // "h300_patient_condition",
                // "h300_mm_department", // ВМУ пока не проверяем
                "h300_referral",
                "h300_location2",
                "h300_date_action2",
            ];

            let errorFields = [];
            fields.forEach(field => {
                if (!newRow[field]) {
                    errorFields.push(field);
                }
            })

            if (errorFields.length) {

                // const handleClickDiagnosisFill = async () => {

                //     const tet = apiRef.current.getCellValue(id, "h300_diagnosis");

                //     console.log(tet);

                //     await apiRef.current.setEditCellValue({
                //         id: id,
                //         field: 'h300_diagnosis_final',
                //         value: ""+tet,
                //     });
                // }

                alertError(<Box>
                    <Box sx={{ fontWeight: 700 }}>При статусе "Ожидает эвакуации" необходимо заполнить следующие поля:</Box>
                    <ul>
                        {errorFields.map(errorField => {
                            let column = columns.find(item => item.field === errorField);
                            // let diagnosis_fill = errorField === "h300_diagnosis_final" ?
                            //     <Box
                            //         sx={{
                            //             textDecoration: "underline",
                            //             fontWeight: 600,
                            //             cursor: "pointer",
                            //         }}
                            //         onClick={handleClickDiagnosisFill}
                            //     >Заполнить из поля "Диагноз при поступлении"</Box> : "";
                            let diagnosis_fill = "";

                            return <li style={{ margin: "4px 0" }} key={errorField}>{column.headerName}{diagnosis_fill}</li>;
                        })}
                    </ul>
                </Box>, {
                    autoClose: 5000,
                    closeOnClick: false,
                });
            } else {
                setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
            }

        } else {
            setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
        }
    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    };

    const processRowUpdate = (newRow) => {
        const editedRow = rows.find((row) => row.h300_id === newRow.h300_id);

        let fioArray = [newRow.jp_surname || "", newRow.jp_name || "", newRow.jp_patronymic || ""];
        let fio = fioArray.join(" ");
        fio = fio.trim();
        // Если статус поменялся, обновляем Дату убытия на текущую
        const updatedRow = {
            ...newRow,
            // h300_date_action2: editedRow.h300_status !== newRow.h300_status ? new Date() : newRow.h300_date_action2,
            h300_date_action2: newRow.h300_date_action2,
            fio,
        };

        setRows(rows.map((row) => (row.h300_id === updatedRow.h300_id ? updatedRow : row)));


        // Формируем строку с датой, для бэка
        let d = null;
        if (updatedRow.h300_date_action2) {
            d = new Date(dayjs(updatedRow.h300_date_action2 ?? undefined));
            d = d.getFullYear() === 1 ? new Date() : d.toISOString();
        }

        let status = newRow.h300_status;
        if (status === "Окончен амбулаторный прием") {
            status = "Выписан";
        } else if (status === "Ведется амбулаторный прием") {
            status = "Находится на лечении";
        }

        let rowToSave = {
            ...newRow,
            h300_date_action2: d,
            h300_status: status,
        }

        dispatch(sendSaveRow({ row: rowToSave }))
        return updatedRow;
    };

    const handleClosePrintMenu = () => {
        setAnchorEl(null);
    };

    const handleClickPrintMenu = (event, row) => {
        // setOpenPrint(!openPrint);

        dispatch(sendGetPatientData({
            data: {
                id: row.jp_id_personnel,
            },
            success: (data) => {
                let patientData = data.personal;
                let currentEvent = patientData.events?.findIndex(event => event.id === row.e300_id);
                let currentHistoryItem = patientData.events[currentEvent]?.history?.find(history => history.id === row.h300_id) || {};

                currentHistoryItem = {
                    ...currentHistoryItem,
                    militaryRank: patientData.militaryRank
                };

                let printData = {
                    patientData,
                    currentHistoryItem,
                    currentEvent,
                    // modalData,
                };

                setPrintData(printData);
            }
        }))

        setAnchorEl(event.currentTarget);
    };

    const handlePrint = (tpl) => {
        setTplId(tpl);
        setAnchorEl(null);
        setTimeout(() => {
            window.print();
        }, 600);
    }

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleClickPrint = (tpl, modal) => {
        if (!modal) {
            handlePrint(tpl);
        } else {
            setOpenModal(tpl);
            // handlePrint(tpl);
        }
    }

    // const handleRowModesModelChange = (newRowModesModel) => {
    //     setRowModesModel(newRowModesModel);
    // };

    columns = columns.map(column => {
        let textAreaCell = {};

        if (column.field === "h300_diagnosis" || column.field === "h300_diagnosis_clinical" || column.field === "h300_diagnosis_final") {
            textAreaCell.renderEditCell = (params) => <EditTextarea {...params} />;
            textAreaCell.width = 260;
        }

        return {
            ...column,
            ...textAreaCell
        };
    })

    // columns.push({
    //     field: 'actions',
    //     type: 'actions',
    //     headerName: '',
    //     sortable: false,
    //     disableReorder: true,
    //     editable: false,
    //     disableColumnMenu: true,
    //     width: 90,
    //     cellClassName: 'actions',
    //     getActions: ({ id, row }) => {
    //         const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

    //         if (isInEditMode) {
    //             return [
    //                 <GridActionsCellItem
    //                     icon={<SaveIcon />}
    //                     label="Сохранить"
    //                     sx={{
    //                         color: 'primary.main',
    //                     }}
    //                     onClick={handleSaveClick(id)}
    //                 />,
    //                 <GridActionsCellItem
    //                     icon={<CancelIcon />}
    //                     label="Отменить"
    //                     className="textPrimary"
    //                     onClick={handleCancelClick(id)}
    //                     color="inherit"
    //                 />,
    //             ];
    //         }

    //         return [
    //             <GridActionsCellItem
    //                 icon={<EditIcon />}
    //                 label="Редактировать"
    //                 className="textPrimary"
    //                 onClick={handleEditClick(id)}
    //                 color="inherit"
    //             />,
    //             <GridActionsCellItem
    //                 icon={<PrintIcon />}
    //                 label="Печать"
    //                 className="textPrimary"
    //                 onClick={(event) => handleClickPrintMenu(event, row)}
    //                 color="inherit"
    //             />,
    //         ];
    //     },
    // },)

    const handleSaveSelected = () => {
        dispatch(sendSaveSelected({
            selected: rowSelectionModel, success: () => {
                setRowSelectionModel([]);
                dispatch(sendGetLossesData());
            }
        }));
    }

    useEffect(() => {
        dispatch(sendGetLossesData());
    }, [dispatch]);

    useEffect(() => {
        let columnsToHide = {};

        if (lossesData.table) {
            columns.forEach(column => {
                if (column.hidden) {
                    columnsToHide[column.field] = false;
                }
            });
            setColumnVisibilityModel(columnsToHide)

            initialRows = lossesData.table.rows;
            setRows(initialRows);
        }
    }, [lossesData.table]);

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    useEffect(() => {
        return () => {
            dispatch(resetLossesData());
        };
    }, []);

    useEffect(() => {
        console.log("EFFECT");
        if (initialRows && apiRef?.current?.restoreState != null && lossesSettings != null && initialRows.length) {
            console.log("EFFECT 2");
            console.log(apiRef.current);

            setTimeout(() => {
                apiRef?.current?.restoreState(fixSettings(lossesSettings, apiRef));
            }, 200);
            // apiRef.current?.setLoading(false);
        }
    }, [apiRef, initialRows, lossesSettings]);


    const handleStateChange = (eventType) => {
        console.log("eventType", eventType);
        let currentState = apiRef.current.exportState();

        let lossesSettings = {
            sorting: currentState.sorting,
            filter: currentState.filter,
            // pinnedColumns: currentState.pinnedColumns,
            columns: {
                columnVisibilityModel: currentState.columns.columnVisibilityModel,
                // orderedFields: currentState.columns.orderedFields,
                // dimensions: currentState.columns.dimensions,
            },
        };
        if (currentState && currentState.columns) {
            appStorage.setItem(userId, {
                ...userSettingsLS,
                "lossesSettings": fixSettings(lossesSettings, apiRef),
            });
        }
    }

    const recountOrderColumn = () => {
        if (apiRef.current) {

            apiRef.current.setLoading(true);
            let sortedRows = apiRef.current?.store?.value?.sorting?.sortedRows || [];
            let filterInfo = apiRef.current?.store?.value?.filter?.filteredRowsLookup || {};

            // console.log(apiRef.current);
            let i = 0;
            let updates = [];

            sortedRows.forEach(row => {
                if (filterInfo[row]) {
                    i++;
                    updates.push({
                        "h300_id": row,
                        "count": i,
                    });
                }
            });
            setTimeout(() => {
                apiRef.current?.updateRows(updates);
                apiRef.current?.setLoading(false);
            }, 600);
        }
    }

    if (apiRef.current?.subscribeEvent) {
        apiRef.current.subscribeEvent('sortModelChange', recountOrderColumn);
        apiRef.current.subscribeEvent('rowCountChange', recountOrderColumn);

        apiRef.current.subscribeEvent("filterModelChange", () => handleStateChange("filterModelChange"));
        apiRef.current.subscribeEvent("columnVisibilityModelChange", () => handleStateChange("columnVisibilityModelChange"));
        // apiRef.current.subscribeEvent("columnWidthChange", () => handleStateChange("columnWidthChange"));
        // apiRef.current.subscribeEvent("columnsChange", () => handleStateChange("columnsChange")); // сробатывает всегда, мешает
        // apiRef.current.subscribeEvent("columnOrderChange", () => handleStateChange("columnOrderChange"));
        apiRef.current.subscribeEvent("sortModelChange", () => handleStateChange("sortModelChange"));
    }

    // console.log(lossesSettings);
    const handleRowClick = (data) => {
        console.log(data);
        navigateWithHistory(`${urlHistory}/${data?.row?.jp_id_personnel}/${data?.row?.e300_id}`); ///${data?.row?.h300_id}

    };

    const handleUndoOut = () => {
        navigateWithHistory('', { action: 'back' });
    }

    return <>
        {tplId === 1 ? <PrintTpl1 printData={printData} /> : null}
        {tplId === 2 ? <PrintTpl2 printData={printData} /> : null}
        {tplId === 3 ? <PrintTpl3 printData={printData} /> : null}
        {tplId === 4 ? <PrintTpl4 printData={printData} /> : null}
        {tplId === 5 ? <PrintTpl5 printData={printData} /> : null}
        {tplId === 6 ? <PrintTpl6 printData={printData} /> : null}
        {<PrintList />}

        <div className="no-print">
            <Dialog
                open={openModal}
                onClose={handleCloseModal}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const modalData = Object.fromEntries(formData.entries());

                        setModalData(modalData);

                        setPrintData({
                            ...printData,
                            modalData
                        });

                        handleCloseModal();
                        handlePrint(openModal);
                    },
                }}
            >

                <DialogContent>

                    <ModalStack>
                        <Checkbox
                            inputProps={{
                                name: 'p1',
                            }} />
                        <DialogContentText>
                            Справка о ранении (контузии, травме, увечье), в соответствии с указом Президента РФ <br />№ <ModalTextField name="p1_number" sx={{ width: "90px" }} /> от <ModalTextField name="p1_date" /> г. оформлена.
                        </DialogContentText>
                    </ModalStack>

                    <ModalStack>
                        <Checkbox
                            inputProps={{
                                name: 'p2',
                            }} />
                        <DialogContentText>
                            Справка на социальные выплаты в соответствии с Указом Президента РФ не оформлялась - ВВК не проводилось.
                        </DialogContentText>
                    </ModalStack>

                    <ModalStack>
                        <Checkbox
                            inputProps={{
                                name: 'p3',
                            }} />
                        <DialogContentText>
                            Освидетельствован ВВК <ModalTextField name="p3_date" /> года, в соответствии с Перечнем, утвержденным постановлением Правительства Российской Федерации от 29.07.1998 года №855, увечье - в перечень
                            <NativeSelect
                                defaultValue="входит"
                                sx={{ ml: 1 }}
                                inputProps={{
                                    name: 'p3_entire',
                                    id: 'uncontrolled-native',
                                }}
                            >
                                <option value="входит">входит</option>
                                <option value="не входит">не входит</option>
                            </NativeSelect>
                        </DialogContentText>
                    </ModalStack>

                    <ModalStack>
                        <Checkbox
                            inputProps={{
                                name: 'p4',
                            }} />
                        <DialogContentText>
                            Медицинское освидетельствование на определение тяжести травмы (увечья) не проводилось.
                        </DialogContentText>
                    </ModalStack>

                    <ModalStack>
                        <Checkbox
                            inputProps={{
                                name: 'p5',
                            }} />
                        <DialogContentText>
                            Эвакуация:
                            <NativeSelect
                                defaultValue=""
                                sx={{ ml: 1 }}
                                inputProps={{
                                    name: 'p5_evaqType',
                                    id: 'uncontrolled-native',
                                }}
                            >
                                <option value=""></option>
                                <option value="сидя">сидя</option>
                                <option value="лёжа">лёжа</option>
                            </NativeSelect>,
                            очередь <ModalTextField name="p5_queue" />
                        </DialogContentText>

                    </ModalStack>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} variant="contained">Закрыть</Button>
                    <Button type="submit" variant="contained">Печатать</Button>
                </DialogActions>
            </Dialog>
            <Grid
                sx={{
                    mt: '15px',
                }}
                container
            >
                <Menu
                    open={openPrint}
                    anchorEl={anchorEl}
                    onClose={handleClosePrintMenu}
                >
                    <MenuList disablePadding>
                        <MenuItem
                            sx={{ pl: 4 }}
                            onClick={() => handleClickPrint(2, true)}
                        >
                            <ListItemIcon>
                                <ArrowRightIcon />
                            </ListItemIcon>
                            <ListItemText primary="Переводной эпикриз" />
                        </MenuItem>

                        <MenuItem
                            sx={{ pl: 4 }}
                            onClick={() => handleClickPrint(1, true)}
                        >
                            <ListItemIcon>
                                <ArrowRightIcon />
                            </ListItemIcon>
                            <ListItemText primary="Выписной эпикриз" />
                        </MenuItem>

                        <MenuItem
                            sx={{ pl: 4 }}
                            onClick={() => handleClickPrint(6)}
                        >
                            <ListItemIcon>
                                <ArrowRightIcon />
                            </ListItemIcon>
                            <ListItemText primary="Эвакуационная карта" />
                        </MenuItem>
                    </MenuList>
                </Menu>
                <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                >
                    <form>
                        <Card
                            sx={{
                                mx: '5px',
                                py: '15px',
                                pl: '10px',
                                pr: '0px',
                                borderRadius: '10px'
                            }}
                            variant="outlined"
                        >
                            <Grid
                                container spacing={2}
                            >
                                <Grid
                                    sx={{
                                        pl: '15px',
                                        textAlign: 'center'
                                    }}
                                    item xs={1} sm={1} ms={1} lg={1} xl={1}
                                >
                                    {/*historyChain.length
                                        ? <IconBtn
                                            thema={buttons.collection1} icon={<UndoOutlinedIcon />} handleClick={handleUndoOut}
                                        />
                                        : <></>
                                    */}
                                </Grid>
                                <Grid
                                    sx={{
                                        textAlign: 'center',
                                        alignContent: 'center',
                                    }}
                                    item xs={9} sm={9} md={9} lg={9} xl={9}
                                >
                                    <Typography
                                        variant="h4"
                                        color={fields.collection2.borderColor}
                                    >
                                        Санитарные потери воисковой части
                                    </Typography>

                                </Grid>

                                <Box
                                    className="losses-table-container"
                                    sx={(theme) => ({
                                        height: "calc(100vh - 150px)",
                                        width: '100%',
                                        pl: 3,
                                        pr: 4,
                                        // '& .editable-cell input, & .editable-cell textarea, & .editable-cell div:nth-of-type(1)': {
                                        //     backgroundColor: 'rgb(245, 229, 182)',
                                        //     color: '#000',
                                        // },
                                        // '& .editable-cell input': {
                                        //     padding: "0 2px",
                                        // },
                                        '& .MuiDataGrid-cell': {
                                            borderLeft: '1px solid lightgray',
                                        },
                                        '& .MuiDataGrid-cell--editable': {
                                            bgcolor: 'rgba(217, 243, 190, 0.3)',
                                            ...theme.applyStyles('dark', {
                                                bgcolor: '#444',
                                            }),
                                        },
                                        '& .MuiDataGrid-cell--editable input, & .MuiDataGrid-cell--editable textarea, & .MuiDataGrid-cell--editable div:nth-of-type(1)': {
                                            backgroundColor: 'rgb(245, 229, 182)',
                                            padding: "0 2px",
                                        },
                                        '& .MuiDataGrid-cell--editable div:nth-of-type(1)': {
                                            padding: "0 10px 0 2px",
                                            fontSize: "0.875rem",
                                        },
                                        '& .MuiDataGrid-cell--editable div:nth-of-type(1) [type="datetime-local"] ': {
                                            padding: "0",
                                        },
                                        '& .MuiDataGrid-columnHeader': {
                                            "& div": {
                                                whiteSpace: "wrap",
                                            }
                                        },
                                        '& .MuiDataGrid-row.Mui-selected': {
                                            backgroundColor: "rgba(25, 118, 210, 0.4) !important",
                                        },
                                        '& .MuiDataGrid-row.Mui-selected:hover': {
                                            backgroundColor: "rgba(25, 118, 210, 0.5) !important",
                                        },
                                        '& .MuiDataGrid-row:hover': {
                                            backgroundColor: "rgba(141, 155, 142, 0.2) !important",
                                        },
                                    })}>
                                    <DataGridPro
                                        sx={{
                                            [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
                                                outline: 'none',
                                            },
                                            [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                                            {
                                                outline: 'none',
                                            },
                                            [`& .${gridClasses.columnHeader}`]:
                                            {
                                                backgroundColor: "#bfc4b5",
                                            },
                                            [`& .${gridClasses.columnHeaderTitle}`]:
                                            {
                                                fontWeight: 600,
                                                letterSpacing: "0.05071em",
                                            },
                                            // [`& .${gridClasses.main}`]:
                                            // {
                                            //     overflow: "visible !important",
                                            //     // height: "calc(100vh - 250px)",
                                            // },
                                            // ".MuiDataGrid-main, .MuiDataGrid-virtualScroller": {
                                            //     // overflow: "visible !important",
                                            //     height: "300px !important",
                                            //     fontSize: "2.4mm !important",
                                            //     "& .MuiDataGrid-row:last-child": {
                                            //         backgroundColor: "brown !important",
                                            //         pageBreakAfter: "avoid !important",
                                            //     },
                                            // },
                                            // '@media print': {
                                            //     [`& .${gridClasses.main}`]:
                                            //     {
                                            //         overflow: "visible !important",
                                            //         height: "auto !important",
                                            //     },
                                            //     [`& .${gridClasses.columnHeaderTitle}`]:
                                            //     {
                                            //         fontWeight: 400,
                                            //         letterSpacing: "0.05071em",
                                            //         whiteSpace: "wrap",
                                            //     },
                                            //     [`& .${gridClasses.columnHeader}`]:
                                            //     {
                                            //         m: 0,
                                            //         p: "3px",
                                            //         height: "30px !important",
                                            //     },
                                            //     [`& .${gridClasses.columnHeader}:nth-of-type(2)`]:
                                            //     {
                                            //         width: '40px !important',
                                            //     },

                                            //     [`& .${gridClasses.cell}:nth-of-type(2)`]: {
                                            //         width: '30px !important',
                                            //     },
                                            //     '& .MuiDataGrid-main': { color: 'red' },
                                            //     "& .MuiDataGrid-main, & .MuiDataGrid-virtualScroller": {
                                            //         overflow: "visible !important",
                                            //         height: "auto !important",
                                            //         fontSize: "2.4mm !important",
                                            //         "& .MuiDataGrid-row:last-child": {
                                            //             backgroundColor: "brown !important",
                                            //             pageBreakAfter: "avoid !important",
                                            //         },
                                            //     },
                                            // },
                                        }}
                                        // pagination
                                        // filterDebounceMs={600}
                                        // initialState={lossesSettings}
                                        // initialState={{
                                        //     sorting: {
                                        //       sortModel: [{ field: 'h300_mh_number', sort: 'asc' }],
                                        //     },
                                        //   }}
                                        apiRef={apiRef}
                                        getRowId={(row) => row.h300_id}
                                        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                                        rows={rows}
                                        columns={columns}
                                        editMode="row"
                                        rowModesModel={rowModesModel}
                                        onRowModesModelChange={handleRowModesModelChange}
                                        onRowEditStop={handleRowEditStop}
                                        getRowHeight={() => 'auto'}

                                        // getEstimatedRowHeight={() => 20}
                                        processRowUpdate={processRowUpdate}
                                        onRowSelectionModelChange={(newRowSelectionModel) => {
                                            setRowSelectionModel(newRowSelectionModel);
                                        }}
                                        rowSelectionModel={rowSelectionModel}
                                        slots={{
                                            toolbar: CustomToolbar.bind(this, { selected: rowSelectionModel, handleSave: handleSaveSelected, columnsToPrint: columnsToPrint, columnsToGroupChange: columnsToGroupChange }),
                                        }}
                                        // isRowSelectable={(params) => params.row.h300_status && params.row.h300_status.toLowerCase() === "ожидает эвакуации"}
                                        // isRowSelectable={true}
                                        // checkboxSelection
                                        disableRowSelectionOnClick
                                        loading={loading}
                                        // density="compact"
                                        columnVisibilityModel={columnVisibilityModel}
                                        onColumnVisibilityModelChange={(newModel) =>
                                            setColumnVisibilityModel(newModel)
                                        }
                                        printOptions={{
                                            hideFooter: true,
                                            hideToolbar: true,
                                            // includeCheckboxes: false,
                                        }}
                                        onRowDoubleClick={handleRowClick}
                                    // columnVisibilityModel={{
                                    //     // status: false,
                                    //     id: false,
                                    //     fio: true,
                                    // }}
                                    // slotProps={{
                                    //     loadingOverlay: {
                                    //         variant: 'linear-progress',
                                    //         noRowsVariant: 'linear-progress',
                                    //     },
                                    // }}
                                    // experimentalFeatures={true}
                                    // autoHeight
                                    />
                                </Box>

                            </Grid>
                        </Card>
                    </form>
                </Grid>
            </Grid>
        </div >
    </>;
}


export const Losses = memo(LossesComponent);
