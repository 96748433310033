import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';


function not(a, b) {
    return a.filter((value) => !b.includes(value));
}
function intersection(a, b) {
    return a.filter((value) => b.includes(value));
}
function union(a, b) {
    return [...a, ...not(b, a)];
}

function SelectAllTransferList(props) {
    const { choices, setChoices, chosen, setChosen } = props

    const [checked, setChecked] = useState([]);    
    const choicesChecked = intersection(checked, choices);
    const chosenChecked = intersection(checked, chosen);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };
    const numberOfChecked = (items) => intersection(checked, items).length;
    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };
    const handleCheckedChosen = () => {
        setChosen(chosen.concat(choicesChecked));
        setChoices(not(choices, choicesChecked));
        setChecked(not(checked, choicesChecked));
    };
    const handleCheckedChoices = () => {
        setChoices(choices.concat(chosenChecked));
        setChosen(not(chosen, chosenChecked));
        setChecked(not(checked, chosenChecked));
    };
    const customList = (title, items) => (
        <Card>
            <CardHeader                
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={
                            numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
                        }
                        disabled={items.length === 0}
                        inputProps={{
                            'aria-label': 'all items selected',
                        }}
                    />
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} выбрано`}
            />
            <Divider />
            <List
                sx={{
                    width: 200,
                    height: 200,
                    bgcolor: 'background.paper',
                    overflow: 'auto',
                }}
                dense
                component="div"
                role="list"
            >
                {items.map((value) => {
                    const labelId = `transfer-list-all-item-label`;

                    return (
                        <ListItemButton
                            key={value}
                            role="listitem"
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.includes(value)}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value} />
                        </ListItemButton>
                    );
                })}
            </List>
        </Card>
    );

    return (
        <Grid
            container
            spacing={2}
            sx={{ justifyContent: 'center', alignItems: 'center' }}
        >
            <Grid item>{customList('Доступные ЛПУ', choices)}</Grid>
            <Grid item>
                <Grid container direction="column" sx={{ alignItems: 'center' }}>
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedChosen}
                        disabled={choicesChecked.length === 0}
                        aria-label="move selected chosen"
                    >
                        &gt;
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedChoices}
                        disabled={chosenChecked.length === 0}
                        aria-label="move selected choices"
                    >
                        &lt;
                    </Button>
                </Grid>
            </Grid>
            <Grid item>{customList('Закрепленные ЛПУ', chosen)}</Grid>
        </Grid>
    );
}

export {
    SelectAllTransferList,
}