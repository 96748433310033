// REST API Контроллер взаимодействия с биллингом
import {
    ResponseCheck, GetFetchAsync, PostFetchAsync, PutFetchAsync, DeleteFetchAsync,
    IsCode200
} from './QueryHandler';
import {
    GetUsersPOST, GetUserEditPOST, GetMMOEditPost,
} from '../helper/UrlController';
import {
    ResponseAlert, ResponseUserCheck,
} from '../models/Response';
import {
    RequestUsers, RequestUser, RequestMMOEdit,
} from '../models/Request';
import {
    appStorage
} from "../../helpers";


let authentication = appStorage.getItem('authentication') || {};
let authParams = {
    authkey: authentication.authkey || "",
    key: authentication.key || "",
}


/**
 * API для получения списка пользователей
 * @returns 
 */
async function GetUsersAsync() {
    let model = {
        alert: {},
        users: [],
        userMMO: [],
    };
    try {
        let req = new RequestUsers().getModel();
        let res = await PostFetchAsync(GetUsersPOST().url, req);
        // Валидация
        if (!ResponseCheck(res)) {
            return res;
        }

        model.alert = new ResponseAlert(
            res.alert.code, res.alert.actionCode, res.alert.title, res.alert.message, res.alert.level, res.alert.sticky
        ).getModel();
        model.users = res.users;
        model.userMMO = res.userMMO;
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}

/**
 * 
 * @param {*} data 
 * @returns 
 */
async function SetUserAsync(data) {
    let model = {
        alert: {},
        key: '',
        authkey: '',
        id: '',
        idPersonnel: '',
        login: '',
        password: '',
        userKey: '',
        role: '',
        mRankS: '',
        authError: '',
        blocked: '',
        fio: '',
        tech: '',
        mmo: [],
    }
    try {
        let req = new RequestUser(data).getModel();
        let res = await PostFetchAsync(GetUserEditPOST().url, req);
        // Валидация
        if (!ResponseCheck(res)) {
            return res;
        }

        model = res;
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}
/**
 * 
 * @param {*} data 
 * @returns 
 */
async function SetMMOEditAsync(data){
    let model = {
        alert: {},
    }
    try {
        let req = new RequestMMOEdit(data).getModel();
        let res = await PostFetchAsync(GetMMOEditPost().url, req);
        // Валидация
        if (!ResponseCheck(res)) {
            return res;
        }

        model = res;
    } catch (err) {
        console.log(err);
        model.alert = new ResponseAlert().getCode500();
    }

    return model;
}


export {
    GetUsersAsync, SetUserAsync, SetMMOEditAsync
}