import { useNavigate } from "react-router-dom";

import {
    selectHistoryChain,
    addToHistoryChain,
    setHistoryChain,
    clearHistoryChain,
} from "../app/reducers/globalSlice";

import { useDispatch, useSelector } from "react-redux";

export const useNavigateWithHistory = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const historyChain = useSelector(selectHistoryChain);

    return (url, props = { action: "add" }) => {
        let { action } = props;

        if (action === "back") {
            if (historyChain.length) {
                let newHistoryChain = historyChain.slice(0, -1);
                dispatch(setHistoryChain(newHistoryChain));
            }
            navigate(-1);
            return;
        }

        if (action === "clear") {
            dispatch(clearHistoryChain());
            navigate(url);
            return;
        }

        if (url && action === "add") {
            dispatch(addToHistoryChain(url));
            navigate(url);
        }
    }
};
