import { useState } from "react";

import { useDispatch } from "react-redux";
import dayjs from "dayjs";

import {
    Button,
    Box,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    DialogTitle,
    FormControlLabel,
    Checkbox,
    FormGroup,
} from "@mui/material";

import PrintIcon from '@mui/icons-material/Print';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';

import {
    GridToolbarContainer,
    GridToolbarExport,
    useGridApiContext,
} from '@mui/x-data-grid-pro';

import { PrintList } from "../PrintList";
import {
    setPrintList,
    sendSavePatientsLossesStamp,
} from "../../lossesSlice";

import { DateTimeFld } from "../../../../components/Fields";
import { appStorage } from "../../../../helpers";



function Field({ column = {}, fieldsState = {}, setFieldsState = () => { }, deleteState = {}, setDeleteState = () => { } }) {
    let type = column.type || "string";
    let values = column.values?.length ? column.values : [];

    let dependencyValues = column.dependencyValues || null;
    let dependencyField = column.dependencyField || null;

    let dependencyFieldValue = dependencyField && fieldsState[dependencyField] ? fieldsState[dependencyField] : null;
    let hasDependency = dependencyValues ? !!dependencyValues[dependencyFieldValue] : false;
    let dependencyValuesList = hasDependency ? dependencyValues[dependencyFieldValue] : values;

    dependencyValuesList = dependencyValuesList || [];
    let disabled = false;

    if (hasDependency && dependencyValuesList.length === 1 && !dependencyValuesList[0]) {
        disabled = true;
        dependencyValuesList = [`Не применимо для такого значения поля "Статус убытия"`]
    }

    const handleChangeInput = (event) => {
        let value = event.target.value;
        let name = event.target.name;

        setFieldsState({
            ...fieldsState,
            [name]: value,
        });
    }

    const handleChangeSelect = (event) => {
        let value = event.target.value;
        let name = event.target.name;

        setFieldsState({
            ...fieldsState,
            [name]: value,
        });
    }
    const handleChangeDate = (event) => {
        console.log(event);
    }

    const handleChangeEnable = (event, name) => {
        let checked = event.target.checked;

        console.log(event);

        setDeleteState({
            ...deleteState,
            [name]: checked,
        });

        if (checked) {
            setFieldsState({
                ...fieldsState,
                [name]: null,
            });
        }
    }

    let filedByType;

    switch (type) {
        case "string":
            filedByType = <TextField
                value={fieldsState[column.field] || ""}
                onChange={handleChangeInput}
                name={column.field}
                label={column.headerName}
                fullWidth
                disabled={deleteState[column.field]}
            />;
            break;

        case "singleSelect":
            filedByType = <FormControl fullWidth>
                <InputLabel id="select-label">{column.headerName}</InputLabel>
                <Select
                    labelId="select-label"
                    onChange={handleChangeSelect}
                    value={fieldsState[column.field] || ""}
                    name={column.field}
                    label={column.headerName}
                    disabled={deleteState[column.field]}
                >
                    {dependencyValuesList.map(value => {
                        return <MenuItem value={value} disabled={disabled}>{value}</MenuItem>;
                    })
                    }
                </Select>
            </FormControl>;
            break;

        case "dateTime":
            filedByType = <DateTimeFld
                name={column.field}
                label={column.headerName}
                thema={{}}
                // onChange={handleChangeInput}
                handleChange={handleChangeDate}
                value={deleteState[column.field] ? null : fieldsState[column.field] || null}
                defaultValue={dayjs()}
                isDisabled={deleteState[column.field]}
            />;
            break;

        default:
            <TextField
                name={column.field}
                label={column.headerName}
                fullWidth
            />
            break;
    }

    return <Box sx={{ display: "flex" }}>
        {filedByType}
        <FormGroup row>
            <FormControlLabel
                sx={{
                    ml: 2,
                }}
                control={<Checkbox
                    // name={column.field}
                    checked={!!deleteState[column.field]}
                    onChange={(event) => handleChangeEnable(event, column.field)}
                />}
                label="удалить"
            />
        </FormGroup>
    </Box>;


}


export function CustomToolbar({ selected = [], handleSave = () => { }, columnsToPrint = "", columnsToGroupChange = [] }) {
    let authentication = appStorage.getItem('authentication') || {};
    let userId = authentication.idPersonnel || "";

    const [fieldsState, setFieldsState] = useState({}); // Создаём единый state для зависимых полей, которые должны знать друг о друге
    const [deleteState, setDeleteState] = useState({});
    const [list, setList] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    // const [openModal, setOpenModal] = useState([]);
    const dispatch = useDispatch();

    const apiRef = useGridApiContext();


    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    let rowSelection = apiRef.current?.state?.rowSelection || [];

    /**
     * 
     */

    let allColumns = apiRef.current?.state?.columns?.lookup || {};
    // let visibleRowsLookup = apiRef.current?.state?.visibleRowsLookup || {};

    let columns = [];

    columnsToGroupChange.forEach(key => {
        if (allColumns[key]) {
            columns.push(allColumns[key]);
        }
    });

    return (<>
        {/** PrintList тут не нужен, но убирать аккуратно, тщательно протестировав печать, особенно отступы. Есть второй PrintList, он нужен*/}
        <PrintList list={list} />

        <Dialog
            fullWidth
            open={openModal}
            onClose={handleCloseModal}
            PaperProps={{
                component: 'form',
                onSubmit: (event) => {
                    event.preventDefault();
                    const formData = new FormData(event.currentTarget);
                    const modalData = Object.fromEntries(formData.entries());
                    dayjs.locale("ru");

                    const setEnable = (field) => {
                        // если выбрана галочка "Удалить", то ставим enable = true. Предполагаем что значение уже стоит пустым (при нажатии на галочку значение обнуляется)
                        if (deleteState[field] === true) {
                            return true;
                        }
                        // если галочка "Удалить" не выбрана и значение не пустое, то тоже ставим enable = true
                        if (!deleteState[field] && modalData[field]) {
                            return true;
                        }

                        return false;
                    }

                    const setValue = (column) => {
                        if (modalData[column.field] === undefined) {
                            if (column.type === "dateTime") {
                                return null;
                            } else {
                                return "";
                            }
                        } else {
                            let d = null;
                            if (column.type === "dateTime") {
                                console.log(modalData[column.field]);
                                if (dayjs(modalData[column.field], "DD.MM.YYYY HH:mm", { locale: "ru" }).isValid()) {
                                    d = new Date(dayjs(modalData[column.field] ?? undefined, "DD.MM.YYYY HH:mm", { locale: "ru" }));
                                    d = d.getFullYear() === 1 ? new Date() : d.toISOString();
                                }

                                return d;
                            } else {
                                return modalData[column.field];
                            }
                        }
                    }

                    let finalData = {};

                    columns.forEach(column => {
                        finalData[column.field] = setValue(column);
                        finalData[`${column.field}_enable`] = setEnable(column.field);
                    });


                    dispatch(sendSavePatientsLossesStamp({
                        data: {
                            ...finalData,
                            h300_id: rowSelection,
                        },
                        success: () => {
                            // dispatch(sendGetLossesData());
                            window.location = "/losses";
                            // console.log(apiRef.current);
                            // apiRef.current.forceUpdate();
                        }
                    }))
                    handleCloseModal();
                },
            }}
        >

            <DialogContent>
                <DialogTitle>Выбрано для эвакуации: {rowSelection.length || 0}</DialogTitle>
                <DialogContentText>
                    {columns.map(column => {
                        return <Box sx={{ m: 1 }} key={column.field}>
                            <Field
                                column={column}
                                fieldsState={fieldsState}
                                setFieldsState={setFieldsState}
                                deleteState={deleteState}
                                setDeleteState={setDeleteState}
                            />
                        </Box>;
                    })}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseModal} variant="contained">Закрыть</Button>
                <Button type="submit" variant="contained">Применить данные ко всем отмеченным записям</Button>
            </DialogActions>
        </Dialog>


        <GridToolbarContainer sx={{ mb: 1 }}>
            {/* <Button
                variant="outlined"
                size="small"
                disabled={!selected.length}
                // onClick={handleSave}
                onClick={handleOpenModal}
            >Завершить эвакуацию {`${selected.length ? ': ' + selected.length : ""}`}</Button> */}

            <Box sx={{ flexGrow: 1 }} />
            <Button
                variant="outlined"
                disabled={!appStorage.getItem(userId)}
                size="small"
                startIcon={<SettingsBackupRestoreIcon />}
                onClick={() => {
                    appStorage.removeItem(userId);
                    window.location.reload();
                }}
            >Сбросить настройки</Button>
            {/* <Button
                variant="outlined"
                size="small"
                startIcon={<PrintIcon />}
                onClick={() => {
                    let visibleRowsLookup = apiRef.current?.state?.visibleRowsLookup || {};
                    let sortedRows = apiRef.current.getSortedRows();

                    let visible = [];
                    sortedRows.forEach(item => {
                        if (visibleRowsLookup[item.h300_id]) {
                            visible.push(item);
                        }
                    });

                    dispatch(setPrintList(visible));

                    setTimeout(() => {
                        window.print();
                    }, 600);
                }}
            >Распечатать</Button> */}
            <GridToolbarExport
                printOptions={{
                    disableToolbarButton: true,
                    hideToolbar: true,
                    hideFooter: true,
                    // getRowsToExport: (wer, wefr, wferr) => {
                    // console.log(wer, wefr, wferr);
                    // return ["444"];
                    // },
                    fields: columnsToPrint,
                }}
                csvOptions={{
                    utf8WithBom: true,
                    delimiter: ";",
                }}
                slotProps={{
                    tooltip: { title: 'Экспорт' },
                    button: { variant: 'outlined' },
                }}
            />
        </GridToolbarContainer>
    </>
    );
}