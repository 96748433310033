import React, { memo, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
    Box,
    Button,
    Card,
    Grid,
    Typography,
} from "@mui/material";

import PersonIcon from '@mui/icons-material/Person';

import {
    selectCurrentPatientId,
    sendGetPatientData,
    setCurrentPatientId,
} from "../personnel/personnelSlice";

import { fields } from '../../app/thema';

import { Search } from "./components/Search";
import { useParams } from "react-router-dom";
import { PatientEdit } from "./components/PatientEdit";

import { urlLosses } from "../../common/helper/UrlRouter";
import { useNavigateWithHistory } from "../../helpers";


const PersonnelComponent = () => {
    const dispatch = useDispatch();
    const navigateWithHistory = useNavigateWithHistory();

    const params = useParams();

    let currentPatientId = useSelector(selectCurrentPatientId);

    useEffect(() => {
        dispatch(setCurrentPatientId(params.patientId));
        if (params.patientId) {
            dispatch(sendGetPatientData({ data: { id: params.patientId } }));
        }
    }, [dispatch, params.patientId]);

    const handleClickLosses = () => {
        navigateWithHistory(`${urlLosses}`);
    }

    return <Grid
        className="no-print"
        sx={{
            mt: '15px',
        }}
        container
    >
        <Grid
            item
            // xs={1}
            md={0}
            lg={1}
        />



        <Grid
            item
            md={12}
            lg={10}
        >
            <Card
                sx={{
                    mx: '5px',
                    py: '15px',
                    px: '10px',
                    borderRadius: '10px'
                }}
                variant="outlined"
            >
                <Grid
                    container spacing={2}
                >
                    <Grid
                        style={{
                            textAlign: 'right',
                        }}
                        item
                        xs={12}
                    >
                        <Button
                            variant="outlined"
                            size="small"
                            onClick={handleClickLosses}
                        >
                            Санитарные потери воисковой части
                        </Button>

                    </Grid>
                    <Grid
                        style={{
                            textAlign: 'center',
                        }}
                        item
                        xs={12}
                    >
                        <Typography
                            variant="h4"
                            color={fields.collection2.borderColor}
                        >
                            Комплектование
                        </Typography>

                    </Grid>


                    <Box sx={{ width: '100%', pl: 3, pr: 3 }}>
                        <Search
                            variant="parse"
                            type="TokenFIO"
                            label={"Личный номер или ФИО"}
                            itemIcon={<PersonIcon sx={{ color: 'text.secondary' }} />}
                            inputColor="#344C1129"
                            sx={{
                                backgroundColor: "#fcfdfd",
                            }}
                            resultItemProps={{
                                sx: {
                                    display: "inline-block",
                                    "&:first-letter": {
                                        textTransform: "uppercase",
                                    }
                                }
                            }}

                        />

                        {currentPatientId ? <>
                            <PatientEdit />
                        </> : null}
                    </Box>

                </Grid>
            </Card>
        </Grid>
        <Grid
            item
            md={0}
            lg={1}
        />

    </Grid>;
}



export const Personnel = memo(PersonnelComponent);
