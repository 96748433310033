import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { styled } from '@mui/material/styles';

import {
    Grid,
    Paper,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import {
    sendGetHistoryPatientsByUser,
    selectHistoryPatients,
} from "../../recentSlice";

import { urlHistory } from '../../../../common/helper/UrlRouter';
import dayjs from "dayjs";
import { useNavigateWithHistory } from "../../../../helpers";

const TableCellHeadStyled = styled(TableCell)(({ theme }) => ({
    fontWeight: 700,
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#444',
    color: theme.palette.background.paper,
    letterSpacing: "0.05071em"
}));

const PatientsListComponent = () => {
    const dispatch = useDispatch();
    const navigateWithHistory = useNavigateWithHistory();

    const historyPatients = useSelector(selectHistoryPatients);

    useEffect(() => {
        dispatch(sendGetHistoryPatientsByUser());
    }, []);

    const handleClickRow = (row) => {
        console.log(row);
        navigateWithHistory(`${urlHistory}/${row.id}/${row.idEvent}`); // /${row.idEvent}

    };

    const dateFormat = (dateString) => {
        if (dayjs(dateString).isValid()) {
            return new Date(dateString).toLocaleString(undefined, { timeStyle: "short", dateStyle: "short", })
        }
        return dateString;
    }

    return <>
        <Grid
            container
            sx={{
                m: 2,
                mt: 4,
            }}
        >
            <TableContainer
                component={Paper}
                sx={{
                    width: "100%",
                }}
            >
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCellHeadStyled>Номер жетона</TableCellHeadStyled>
                            <TableCellHeadStyled>ФИО</TableCellHeadStyled>
                            <TableCellHeadStyled>Диагноз</TableCellHeadStyled>
                            <TableCellHeadStyled>Дата поступления</TableCellHeadStyled>
                            <TableCellHeadStyled>Лечащий врач</TableCellHeadStyled>
                            <TableCellHeadStyled>Степень тяжести</TableCellHeadStyled>
                            <TableCellHeadStyled>Статус</TableCellHeadStyled>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {historyPatients.map((row) => (
                            <TableRow
                                key={row.id}
                                sx={{
                                    'td': { border: "1px solid gray !important" },
                                    cursor: "pointer"
                                }}
                                onClick={() => handleClickRow(row)}
                            // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>{row.suid}</TableCell>
                                <TableCell>{row.fio}</TableCell>
                                <TableCell>{row.diagnosis}</TableCell>
                                <TableCell>{dateFormat(row.dateAction1)}</TableCell>
                                <TableCell>{row.doctor}</TableCell>
                                <TableCell>{row.gravityInjury}</TableCell>
                                <TableCell>{row.status}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    </>;
}

export const PatientsList = memo(PatientsListComponent);
