import { configureStore } from '@reduxjs/toolkit';

import cardindexPersonnelSlice from './reducers/cardindexPersonnelSlice';
import globalSlice from './reducers/globalSlice';
import historyReducer from '../features/history/historySlice';
import recentReducer from '../features/recent/recentSlice';
import hospitalReducer from '../features/hospital/hospitalSlice';
import evacuationReducer from '../features/evacuation/evacuationSlice';
import personnelReducer from '../features/personnel/personnelSlice';
import PSOReducer from '../features/PSO/PSOSlice';
import uploadReducer from '../features/upload/uploadSlice';
import statisticReducer from '../features/statistic/statisticSlice';
import lossesReducer from '../features/losses/lossesSlice';


export const store = configureStore({
    reducer: {
        global: globalSlice,
        cardindexPersonnel: cardindexPersonnelSlice,
        history: historyReducer,
        hospital: hospitalReducer,
        recent: recentReducer,
        evacuation: evacuationReducer,
        personnel: personnelReducer,
        PSO: PSOReducer,
        upload: uploadReducer,
        statistic: statisticReducer,
        losses: lossesReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});