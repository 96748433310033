import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import { useFormik } from 'formik';
import { useNavigate } from "react-router-dom";
import { appStorage } from '../helpers';
import { fields, buttons, dialogs, cards } from '../app/thema';

import { Grid, Card, Typography } from '@mui/material';

import { alertSuccess, alertWarn, alertError, alertInfo } from '../common/helper/ToastAlert';
import { IsCode200, IsCode503 } from '../common/api/QueryHandler';
import { urlManagement } from '../common/helper/UrlRouter';
import { GetMMOListAsync } from '../common/api/Export';
import { SetMMOEditAsync } from '../common/api/Management';

import { LpuEditDlg } from '../components/Dialogs';

import { MilitaryOrganizationsCard } from '../components/Cards';
import { StartIconBtn, EndIconBtn, IconBtn } from '../components/Buttons';

import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
import AddBusinessIcon from '@mui/icons-material/AddHomeWork';
import { amET } from '@mui/material/locale';


/**
 * 
 * @returns 
 */
export default function FormaByMilitaryOrganizations() {
    const navigate = useNavigate();
    const [cache, setCache] = useState(appStorage.getItem('authentication'));
    const [lpuCard, setLpuCard] = useState([]);
    const [open, setOpen] = useState(false);
    const [isUpdate, setIsUpdate] = useState(true);
    const [isBlockField, setIsBlockField] = useState(false);


    // Загрузка данных
    const handleDataLoader = async () => {
        let res = await GetMMOListAsync();
        let lpu = [];
        res.identified.map((elem) => {
            lpu.push({
                key: elem.id,
                name: elem.name,
                fullName: elem.full_Name
            });
        });
        setLpuCard(lpu);
        switch (cache.role) {
            case 'admin':
                setIsBlockField(false);
                break;
            default:
                setIsBlockField(true);
                break;
        }
    };
    const handleClickAccept = () => {
        formik.values.name = '';
        formik.values.fullName = '';

        setIsUpdate(false);
        setOpen(true);
    };
    const handleClickUpdate = (key) => {
        let elem = lpuCard.find(e => e.key === key);

        formik.values.oldName = elem.name;
        formik.values.name = elem.name;
        formik.values.fullName = elem.fullName;

        setIsUpdate(true);
        setOpen(true);
    };
    
    const handleClickSave = () => {
        isUpdate
            ? handleClickUpdateSave() 
            : handleClickAcceptSave();
    };
    const handleClickAcceptSave = async () => {
        let req = {
            id: uuidv4(),
            oldName: formik.values.name,
            name: formik.values.name,
            full_Name: formik.values.fullName,
        };
        let res = await SetMMOEditAsync(req);
        // Валидация запроса
        if (!IsCode200(res)) {
            if (IsCode503(res)) {
                alertError('Сервис недоступен!');
                setOpen(false);

                return;
            };

            alertWarn('Не удалось создать запись!');
            setOpen(false);

            return;
        };
        
        setOpen(false);
        alertSuccess('Запись успешно добавлена!');
        handleDataLoader();
    };
    const handleClickUpdateSave = async () => {
        let elem = lpuCard.find(e => e.name == formik.values.oldName);
        let req = {
            id: elem.key,
            oldName: elem.name,
            name: formik.values.name,
            full_Name: formik.values.fullName,
        };
        let res = await SetMMOEditAsync(req);
        // Валидация запроса
        if (!IsCode200(res)) {
            if (IsCode503(res)) {
                alertError('Сервис недоступен!');
                setOpen(false);

                return;
            };

            alertWarn('Не удалось создать запись!');
            setOpen(false);

            return;
        };

        setOpen(false);
        alertSuccess('Запись успешно добавлена!');
        handleDataLoader();
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleUndoOut = () => {
        navigate(urlManagement);
    };

    const handleFormAction = (values) => {
    };

    useEffect(() => {
        handleDataLoader();
    }, []);

    const model = {
        header: {
            thema: fields.collection2,
            label: 'Медицинские организации',
        },
        cardItem: {
            thema: cards.collection1,
            fontSize: '16pt',
            img: '../img/MilitaryOrganizations.jpg',
            title: 'Мишин А.В.',
            href: ''
        },
        items: [
            {
                key: 1,
                title: 'ВПСГ "442 ВКГ" МО РФ',
                fullName: 'ВПСГ "442 ВКГ" МО РФ',
            },
            {
                key: 2,
                title: 'Медрота 41 МСП (К)',
                fullName: 'Медрота 41 МСП (К)',
            },
            {
                key: 3,
                title: 'Медрота 41 МСП (М)',
                fullName: 'Медрота 41 МСП (М)',
            },
            {
                key: 4,
                title: 'Медрота 41 МСП (Т)',
                fullName: 'Медрота 41 МСП (Т)',
            }
        ],
        undoBtn: {
            thema: buttons.collection1,
            icon: <UndoOutlinedIcon />,
        },
        addBtn: {
            thema: buttons.collection1,
            icon: <AddBusinessIcon />
        }
    };
    const context = {
        name: '',
        oldName: '',
        fullName: '',
    };
    const validationSchema = Yup.object().shape({
    });

    const formik = useFormik({
        onSubmit: handleFormAction,
        validationSchema: validationSchema,
        initialValues: context
    });

    // Формирование карточки пользователя
    const CardItem = (props) => {
        const { name, fullName, id } = props;
        return (
            <Grid
                item xs={12} sm={6} md={6} lg={4} xl={4}
            >
                <MilitaryOrganizationsCard
                    thema={model.cardItem.thema} fontSize={model.cardItem.fontSize} img={model.cardItem.img}
                    title={name} fullName={fullName} 
                    isDisabled={true}
                    handleClick={() => handleClickUpdate(id)}
                />
            </Grid>
        )
    };

    return (
        <>
            <Grid
                sx={{
                    mt: '5px',
                    px: '10px',
                }}
                spacing={2}
                container
            >
                <Grid
                    item xs={12} sm={12} md={12} lg={12} xl={12}
                >
                    <Card
                        sx={{
                            py: '15px',
                            borderRadius: '10px'
                        }}
                        variant="outlined"
                    >
                        <Grid
                            container
                        >
                            <Grid
                                sx={{
                                    pl: '15px',
                                    textAlign: 'center'
                                }}
                                item xs={1} sm={1} ms={1} lg={1} xl={1}
                            >
                                <IconBtn
                                    thema={model.undoBtn.thema} icon={model.undoBtn.icon}
                                    handleClick={handleUndoOut}
                                />
                            </Grid>
                            <Grid
                                sx={{
                                    textAlign: 'center',
                                    alignContent: 'center',
                                }}
                                item xs={10} sm={10} md={10} lg={10} xl={10}
                            >
                                <Typography
                                    variant="h4" color={model.header.thema.borderColor}
                                >
                                    {model.header.label}
                                </Typography>
                            </Grid>
                            <Grid
                                sx={{
                                    pr: '15px',
                                    textAlign: 'center'
                                }}
                                item xs={1} sm={1} ms={1} lg={1} xl={1}
                            >
                                {isBlockField
                                    ? <></>
                                    : <IconBtn
                                        thema={model.addBtn.thema} icon={model.addBtn.icon}
                                        handleClick={handleClickAccept}
                                    />
                                }
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                {lpuCard.map((elem) => {
                    return (
                        <CardItem
                            formik={formik}
                            key={elem.key} id={elem.key} name={elem.name} fullName={elem.fullName}
                        />
                    );
                })}
            </Grid>
            <LpuEditDlg
                formik={formik} open={open} isUpdate={isUpdate} isBlockField={isBlockField}
                handleClose={handleClose} handleClick={handleClickSave}
            />
        </>
    );
}
