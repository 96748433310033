import React, { memo, useState, useMemo, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
// import { MIN_SEARCH_TEXT, MAX_SEARCH_WORDS } from "constants";

import { createTheme } from '@mui/material/styles';
import { fields, buttons } from '../../../../app/thema';
import {
    Divider,
    Box,
    Stack,
    NativeSelect,
    List,
    ListItemButton,
    ListItemText,
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    Slide,
    Button,
    TextField,
    DialogActions,
    DialogContent,
    DialogContentText,
    Checkbox,
    FormHelperText,
    DialogTitle,
    useMediaQuery,
} from '@mui/material';


import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { Handbook300Async, TechAsync } from '../../../../common/api/Handbook'; // , MilitaryHandbooksAsync
import { DateTimeDesktopFld, MultilineFld, TextFld } from '../../../../components/Fields';
import { EndIconBtn } from '../../../../components/Buttons';
import { AutoCompleteCbx } from '../../../../components/ComboBoxs';

import {
    selectCurrentHistoryItem,
    setCurrentHistoryItem,
    selectPatientData,
    // updateCurrentHistoryItem,
    // updatePatientData,
    sendUpdate300,
    sendGetPatientData,
} from "../../historySlice";

import { PrintTpl_1 as PrintTpl1 } from "../PrintTpl_1";
import { PrintTpl_2 as PrintTpl2 } from "../PrintTpl_2";
import { PrintTpl_3 as PrintTpl3 } from "../PrintTpl_3";
import { PrintTpl_4 as PrintTpl4 } from "../PrintTpl_4";
import { PrintTpl_5 as PrintTpl5 } from "../PrintTpl_5";
import { PrintTpl_6 as PrintTpl6 } from "../PrintTpl_6";
import { PrintTpl_7 as PrintTpl7 } from "../PrintTpl_7";

import { PatientInfo } from "../PatientInfo";
// import { PrintBlock } from "../PrintBlock";
import { PrintMenu } from "../PrintMenu";

import "./styles.css";
import { lightGreen } from "@mui/material/colors";
import { useLocation, useNavigate } from "react-router-dom";
import { urlHistory } from "../../../../common/helper/UrlRouter";

import stayModeJson from '../../../../shared/stayMode.json';
import { Troubleshoot } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
// import gravityOfInjuryJson from '../../../../shared/gravityOfInjury.json';


const SERVICES = {
    pharmacotherapy: {
        url: "https://ecp.socmedica.com",
        title: "Электронный клинический фармаколог",
    },
    complaint: {
        url: "https://eta.socmedica.com",
        title: "Электронный ассистент терапевта",
    },
}

const model = {
    stayMode: {
        component: AutoCompleteCbx,
        thema: fields.collection2,
        type: 'list',
        fieldKey: 'stayMode',
        label: 'Режим пребывания',
    },
    status: {
        component: AutoCompleteCbx,
        thema: fields.collection2,
        type: 'list',
        fieldKey: 'status',
        label: 'Статус убытия',
    },
    location: {
        component: AutoCompleteCbx,
        thema: fields.collection2,
        type: 'list',
        fieldKey: 'location',
        label: 'Место оказания помощи',
    },
    hospitalizationDate: {
        component: DateTimeDesktopFld,
        thema: fields.collection2,
        type: 'date',
        fieldKey: 'hospitalizationDate',
        label: 'Время поступления/обращения',
    },
    gravityOfInjury: {
        component: AutoCompleteCbx,
        thema: fields.collection2,
        type: 'list',
        fieldKey: 'gravityOfInjury',
        label: 'Степень тяжести',
        dataType: "eventData",
    },
    eventDate: {
        component: DateTimeDesktopFld,
        thema: fields.collection2,
        type: 'date',
        fieldKey: 'eventDate',
        label: 'Дата ранения',
        dataType: "eventData",
    },
    disease: {
        component: MultilineFld,
        thema: fields.collection2,
        type: 'text',
        fieldKey: 'disease',
        label: 'Диагноз при поступлении',
        rows: '4',
    },
    hospitalWard: {
        component: TextFld,
        thema: fields.collection2,
        type: 'text',
        fieldKey: 'hospitalWard',
        label: 'Палата',
    },
    doctor: {
        component: TextFld,
        thema: fields.collection2,
        type: 'text',
        fieldKey: 'doctor',
        label: 'Лечащий врач',
    },
    complaint: {
        component: MultilineFld,
        thema: fields.collection2,
        type: 'text',
        fieldKey: 'complaint',
        label: 'Жалобы',
        rows: '4',
    },
    anamnesis: {
        component: MultilineFld,
        thema: fields.collection2,
        type: 'text',
        fieldKey: 'anamnesis',
        label: 'Анамнез',
        rows: '4',
    },
    circumstances: {
        component: MultilineFld,
        thema: fields.collection2,
        type: 'text',
        fieldKey: 'circumstances',
        label: 'Обстоятельства ранения/болезни',
        rows: '4',
        dataType: "eventData",
    },
    nature: {
        component: MultilineFld,
        thema: fields.collection2,
        type: 'text',
        fieldKey: 'nature',
        label: 'Характер ранения',
        rows: '4',
        dataType: "eventData",
    },
    diagnosisStudies: {
        component: MultilineFld,
        thema: fields.collection2,
        type: 'text',
        fieldKey: 'diagnosisStudies',
        label: 'Инструментально-лабораторные исследования',
        rows: '4',
    },
    diagnosisFinal: {
        component: MultilineFld,
        thema: fields.collection2,
        type: 'text',
        fieldKey: 'diagnosisFinal',
        label: 'Заключительный диагноз',
        rows: '4',
    },
    therapy: {
        component: MultilineFld,
        thema: fields.collection2,
        type: 'text',
        fieldKey: 'therapy',
        label: 'Операция',
        rows: '4',
    },
    helping: {
        component: MultilineFld,
        thema: fields.collection2,
        type: 'text',
        fieldKey: 'helping',
        label: 'Оказана помощь',
        rows: '4',
    },
    pharmacotherapy: {
        component: MultilineFld,
        thema: fields.collection2,
        type: 'text',
        fieldKey: 'pharmacotherapy',
        label: 'Фармакотерапия',
        rows: '4',
    },
    risks: {
        component: MultilineFld,
        thema: fields.collection2,
        type: 'text',
        fieldKey: 'risks',
        label: 'Риски',
        rows: '4',
    },
    evacuationType: {
        component: AutoCompleteCbx,
        thema: fields.collection2,
        type: 'list',
        fieldKey: 'evacuationType',
        label: 'Вид эвакуации',
    },
    referral: {
        component: AutoCompleteCbx,
        thema: fields.collection2,
        type: 'list',
        fieldKey: 'referral',
        label: 'Направление эвакуации',
    },
    evacuationLocation: {
        component: AutoCompleteCbx,
        thema: fields.collection2,
        type: 'list',
        fieldKey: 'evacuationLocation',
        label: 'Направление убытия',
    },
    dischargeDate: {
        component: DateTimeDesktopFld,
        thema: fields.collection2,
        type: 'date',
        fieldKey: 'dischargeDate',
        label: 'Время эвакуации/выписки',
    },
    wardRegime: {
        component: TextFld,
        thema: fields.collection2,
        type: 'text',
        fieldKey: 'wardRegime',
        label: 'Подписант',
    },

    // militaryRank: {
    //     component: AutoCompleteCbx,
    //     thema: fields.collection2,
    //     type: 'list',
    //     fieldKey: 'militaryRank',
    //     label: 'Воинское звание',
    // },


    // diagnosisClinical: {
    //     component: MultilineFld,
    //     thema: fields.collection2,
    //     type: 'text',
    //     fieldKey: 'diagnosisClinical',
    //     label: 'Диагноз клинический',
    //     rows: '4',
    // },


}

const ModalTextField = ({ name, sx = {}, ...props }) => {
    return <TextField
        margin="dense"
        size="small"
        id={name}
        name={name}
        type="text"
        variant="standard"
        sx={{
            m: 0,
            ml: 1,
            width: "80px",
            ...sx,
        }}
        {...props}
    />;
}

const ModalStack = ({ children }) => {
    return <Stack
        direction="row"
        sx={{
            justifyContent: "flex-start",
            alignItems: "flex-start",
            mx: 0,
            my: 1,
            "& p": {
                mt: 1,
            }
        }}>
        {children}
    </Stack>;
}


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * @shitfix
 * @title Суперкостыль
 * @description При смене Режима пребывания на Амбулаторно, меняется Статус убития с "Выписан" на "Ведется амбулаторный прием" (и значение и в списке). На сервер всегда отправляется "Выписан"
 */

const TRIGER_STAY_MODE = "Амбулаторно";
const STATUS_ORIGINAL = "Выписан";
const STATUS_ORIGINAL2 = "Находится на лечении";
const STATUS_FAKE = "Окончен амбулаторный прием";
const STATUS_FAKE2 = "Ведется амбулаторный прием";


const getFakedStatus = (status) => {
    switch (status) {
        case STATUS_ORIGINAL:
            return STATUS_FAKE;
        case STATUS_ORIGINAL2:
            return STATUS_FAKE2;

        default:
            return status;
    }
}

const getOriginalStatus = (status) => {
    switch (status) {
        case STATUS_FAKE:
            return STATUS_ORIGINAL;
        case STATUS_FAKE2:
            return STATUS_ORIGINAL2;

        default:
            return status;
    }
}

const COSTYL_GET_VALUE = ({ stayMode, value }) => {
    if (stayMode === TRIGER_STAY_MODE) {
        return getFakedStatus(value);
    }
    return value;
}


const COSTYL_TECH = ({ stayMode, setTechList, formik, techList }) => {
    let newStatusList = { ...techList };
    let statusList = techList.status || [];

    if (stayMode === TRIGER_STAY_MODE) {
        newStatusList = statusList.map(item => {
            return getFakedStatus(item);
        });

        if (formik.values.status?.label === STATUS_ORIGINAL || formik.values.status?.label === STATUS_ORIGINAL2) {
            let newStatus = {
                id: formik.values.status?.id || "",
                label: getFakedStatus(formik.values.status?.label),
            };

            formik.setFieldValue("status", newStatus);
            formik.values["status"] = newStatus;
        }

    } else {
        newStatusList = statusList.map(item => {
            return getOriginalStatus(item);
        });

        if (formik.values.status?.label === STATUS_FAKE || formik.values.status?.label === STATUS_FAKE2) {
            let newStatus = {
                id: formik.values.status?.id || "",
                label: getOriginalStatus(formik.values.status?.label),
            };
            formik.setFieldValue("status", newStatus);
            formik.values["status"] = newStatus;
        }
    }

    setTechList({
        ...techList,
        "status": newStatusList,
    });
}

const ListItemComponent = ({
    title,
    currentHistoryItem,
    currentEvent,
    editMode,
    formik,
    locationsList,
    evacuationLocationsList,
    techList = {},
    setEvacuationLocationsList,
    setTechList,
    onClickAnalize,
}) => { //, militaryRanksList
    let key = title.fieldKey;

    let emptyOption = { id: "", label: "" };

    let status = techList.status?.length ? techList.status.map((item, index) => ({ id: index, label: item })) : [];
    let referral = techList.referral?.length ? techList.referral.map((item, index) => ({ id: index, label: item })) : [];
    let gravityOfInjury = techList.gravityOfInjury?.length ? techList.gravityOfInjury.map((item, index) => ({ id: index, label: item })) : [];
    let stayMode = techList.stayMode?.length ? techList.stayMode.map((item, index) => ({ id: index, label: item })) : [];
    let evacuationType = techList.evacuationType?.length ? techList.evacuationType.map((item, index) => ({ id: index, label: item })) : [];

    referral.unshift(emptyOption);
    gravityOfInjury.unshift(emptyOption);
    stayMode.unshift(emptyOption);
    evacuationType.unshift(emptyOption);

    const elems = {
        status,
        referral,
        gravityOfInjury,
        stayMode,
        evacuationType,
        evacuationLocation: evacuationLocationsList,
        location: locationsList,
    };

    const handleChangeDate = (key, val) => {
        let d = new Date(dayjs(val ?? undefined));

        d = d.getFullYear() === 1 ? new Date() : d.toISOString();
        formik.setFieldValue(key, d);
    }

    if (!editMode || !model[key] || !model[key].component) {
        let dataFrom = model[key].dataType === "eventData" ? currentEvent : currentHistoryItem;
        let value = dataFrom[title.fieldKey] || ""

        if (key === "status") {
            value = COSTYL_GET_VALUE({
                stayMode: currentHistoryItem.stayMode,
                value,
            });
            // console.log(value);
        }

        return <>
            <ListItemText
                sx={{ ml: 2 }}
                primary={title.label}
                secondary={title.type === "date" && value ? new Date(value).toLocaleString(undefined, { timeStyle: "short", dateStyle: "short", }) : value || "-"}
            />
            {SERVICES[key] && value ?
                <Button
                    sx={{
                        ml: 2
                    }}
                    size="small"
                    variant="outlined"
                    endIcon={<Troubleshoot />}
                    onClick={() => onClickAnalize(key)}
                >Анализировать</Button>
                : null}
        </>;
    } else {
        if (model[key]) {
            const FieldComponent = model[key].component;
            let disabledText = "";

            if (elems[key] && elems[key][0]?.disabled === true) {
                let depFieldLabel = formik.values?.status?.label ? `"${formik.values.status.label}"` : "";

                disabledText = <FormHelperText>{`${elems[key][0]?.label} ${depFieldLabel}`}</FormHelperText>;
            }


            return <Box
                sx={{ m: 2 }}>
                <FieldComponent
                    size={'small'}
                    variant={'outlined'}
                    thema={model[key].thema}
                    type={model[key].type}
                    name={key}
                    rows={model[key].rows || ""}
                    value={formik.values[key]}
                    isDisabled={elems[key] && elems[key][0]?.disabled === true}
                    label={title.label}
                    handleBlur={formik.handleBlur}
                    handleChange={(val) => {

                        // console.log("val", val);
                        // console.log("key", key);
                        // console.log("techList", techList);

                        if (title.type === "date") {

                            handleChangeDate(key, val);
                        } else {
                            formik.handleChange(val);
                        }

                    }}
                    touched={formik.touched[key]}
                    error={formik.errors[key]}
                    elems={elems[key] || []}
                    val={formik.values[key]}
                    setFieldValue={(key, val) => {
                        // console.log("key", key);
                        // console.log("val", val);
                        // console.log("techList", techList);

                        if (key === "stayMode") {
                            COSTYL_TECH({
                                stayMode: val.label,
                                setTechList,
                                techList,
                                formik,
                            });
                        }

                        let value = val?.label || "";
                        let techKeys = Object.keys(techList);
                        // console.log("techKeys", techKeys);
                        // console.log("value", value);

                        let dependencyFullKey = "";
                        let dependencySecondKey = "";

                        techKeys.forEach(k => {
                            let splitedKey = k.split("_");
                            // console.log("splitedKey", splitedKey);

                            if (splitedKey.length === 2 && splitedKey[0] === key) {
                                dependencyFullKey = k;
                                dependencySecondKey = splitedKey[1];
                            }
                        });

                        let newList = [];
                        if (dependencyFullKey && dependencySecondKey) {
                            dependencySecondKey = dependencySecondKey.charAt(0).toLowerCase() + dependencySecondKey.slice(1);
                            newList = techList[dependencyFullKey][value] || techList[dependencySecondKey];
                            newList = newList.length ? newList.map((item, index) => ({ id: index, label: item })) : [];
                            formik.setFieldValue("evacuationLocation", { id: "", label: "" });
                            formik.values["evacuationLocation"] = { id: "", label: "" };

                            // console.log(newList);
                            if (newList.length === 1 && !newList[0].label) {
                                newList[0] = { id: "no", label: "Не применимо для статуса", disabled: true };
                            }

                            setEvacuationLocationsList(newList);
                            // { id: "no", label: "Не применимо для данного статуса" }
                        }

                        // console.log("dependencyFullKey", dependencyFullKey);
                        // console.log("dependencySecondKey", dependencySecondKey);
                        // console.log("newList", newList);

                        return formik.setFieldValue(key, val);
                    }}
                    handleSetState={() => { }}
                />
                {disabledText || null}
            </Box>;
        }
    }
}


const HistoryItemComponent = ({ currentEvent, notEnded, isLast }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    let currentHistoryItem = useSelector(selectCurrentHistoryItem);
    const patientData = useSelector(selectPatientData);
    const [tplId, setTplId] = useState(0);
    const [editMode, setEditMode] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openServiceDialog, setOpenServiceDialog] = useState(false);

    // const [locationState, setLocationState] = useState({ id: '0', label: "EMPTY" });
    const [modalData, setModalData] = useState({});
    const [locationsList, setLocationsList] = useState([]);
    const [evacuationLocationsList, setEvacuationLocationsList] = useState([]);
    const [techList, setTechList] = useState([]);
    // const [militaryRanksList, setMilitaryRanksList] = useState([]);

    // currentHistoryItem = {
    //     ...currentHistoryItem,
    //     militaryRank: patientData.militaryRank
    // };

    let printData = {
        patientData,
        currentHistoryItem,
        currentEvent,
        modalData,
    };

    const handleFormAction = (values) => {
    };

    const formik = useFormik({
        onSubmit: handleFormAction,
        validationSchema: Yup.object().shape({}),
        initialValues: {
            dischargeDate: null,
            disease: "", // diagnosis
            evacuationLocation: { id: '', label: '' },
            evacuationType: { id: '', label: '' },
            referral: { id: '', label: '' },
            helping: "",
            anamnesis: "",
            circumstances: "",
            nature: "",
            complaint: "",
            wardRegime: "",
            risks: "",
            hospitalizationDate: null,
            eventDate: null,
            location: { id: '', label: '' },
            status: { id: '', label: '' },
            doctor: "",
            hospitalWard: "",
            gravityOfInjury: { id: '', label: '' },
            stayMode: { id: '', label: '' },
            // militaryRank: { id: '', label: '' },
        }
    });

    let fullPrint = false;

    if (notEnded === false && isLast === true) {
        fullPrint = true;
        // console.log("fullPrint", fullPrint);
    }

    /**
     * shitfix
     * @description нужно добавить проверку. В условия к fullPrint нужно добавить проверку. Печатать можно всё, если не прошло 24 часа. А пока хардкодим всегда true
     */
    fullPrint = true;


    const handleCloseHistoryItem = () => {
        const canGoBack = location.key !== 'default';

        if (canGoBack) {
            navigate(-1);
        } else {
            navigate(`${urlHistory}/${patientData.id}/`, { replace: false });
        }
        dispatch(setCurrentHistoryItem({}));
        setEditMode(false);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleEdit = () => {
        if (!editMode) {
            Object.keys(model).forEach(key => {

                let value;

                if (model[key].dataType === "eventData") {
                    value = currentEvent[key] || "";
                } else {
                    value = currentHistoryItem[key] || "";
                }

                value = model[key].type === "list" ? { id: "", label: value } : value;

                formik.setFieldValue(model[key].fieldKey, value);
                formik.values[model[key].fieldKey] = value;
            });
            setEditMode(true);





            /**
             * @todo Повторяющийся код. Подумать как оптимизировать.
             */

            // console.log("key", key);
            // console.log("techList", techList);

            let value = currentHistoryItem.status || "";
            // console.log("val", value);
            let techKeys = Object.keys(techList);
            // console.log("techKeys", techKeys);
            // console.log("value", value);

            let dependencyFullKey = "";
            let dependencySecondKey = "";

            techKeys.forEach(k => {
                let splitedKey = k.split("_");
                // console.log("splitedKey", splitedKey);

                if (splitedKey.length === 2 && splitedKey[0] === "status") {
                    dependencyFullKey = k;
                    dependencySecondKey = splitedKey[1];
                }
            });

            let newList = [];
            if (dependencyFullKey && dependencySecondKey) {
                dependencySecondKey = dependencySecondKey.charAt(0).toLowerCase() + dependencySecondKey.slice(1);
                newList = techList[dependencyFullKey][value] || techList[dependencySecondKey];
                newList = newList.length ? newList.map((item, index) => ({ id: index, label: item })) : [];


                if (newList.length === 1 && !newList[0].label) {
                    newList[0] = { id: "no", label: "Не применимо для статуса", disabled: true };
                }


                setEvacuationLocationsList(newList);
                // formik.setFieldValue("evacuationLocation", { id: "", label: "" });
                // formik.values["evacuationLocation"] = { id: "", label: "" };
            } else {
                setEvacuationLocationsList(techList["locationWhere"]);
            }

            // console.log("dependencyFullKey", dependencyFullKey);
            // console.log("dependencySecondKey", dependencySecondKey);
            // console.log("newList", newList);

            // formik.setFieldValue(key, val);

            // <==












            COSTYL_TECH({
                stayMode: currentHistoryItem.stayMode,
                setTechList,
                techList,
                formik,
            });

        } else {
            setEditMode(false);
        }
    };

    const handleClickSave = () => {

        // console.log(formik.values.stayMode?.label);

        let stayModeObject = stayModeJson.find(item => item.label === formik.values.stayMode?.label) || {};
        let stayMode = stayModeObject.enum || "none";

        let status = getOriginalStatus(formik.values.status?.label);


        /**
 * @todo вынести в константы и везде использовать
 */
        const types = {
            "Ранение": "injury",
            "Травма": "trauma",
            "Заболевание": "diseases",
            "Ядерное": "nuclear",
            "Бактериальное": "bacterial",
            "Химическое": "chemical",
            "Ожог": "burn",
            "Отморожение": "frostbite",
            "Инфекция": "infectious",
            "Смерть": "death",
        };

        const eventType = types[currentEvent.formType] || "injury";

        let saveData = {
            idEvent: currentEvent.id,
            idHistory: currentHistoryItem.id,
            eventType,
            diagnosis: formik.values.disease,
            locationMedicalAssistance: formik.values.location?.label || "",
            locationWhere: formik.values.evacuationLocation?.label || "",
            dateOfVisits: formik.values.hospitalizationDate || null,
            dateOfEvacuation: formik.values.dischargeDate || null,
            helpProvided: formik.values.helping || "",
            doctor: formik.values.doctor,
            // militaryRank: formik.values.militaryRank?.label || "",
            recommendations: currentHistoryItem.recommendations,
            status: status || "",

            complaint: formik.values.complaint || "",
            anamnesis: formik.values.anamnesis || "",
            circumstances: formik.values.circumstances || "",
            nature: formik.values.nature || "",
            dateOfInjury: formik.values.eventDate || null,
            gravityOfInjury: formik.values.gravityOfInjury?.label || "",
            stayMode: stayMode,
            idPersonnel: currentEvent.idPersonnel,
            locationWhereInjury: currentEvent.location,

            hospitalWard: formik.values.hospitalWard,
            evacuationType: formik.values.evacuationType?.label || "",
            referral: formik.values.referral?.label || "",
            diagnosisClinical: formik.values.diagnosisClinical,
            wardRegime: formik.values.wardRegime,
            diagnosisFinal: formik.values.diagnosisFinal || "",
            therapy: formik.values.therapy || "",
            // diagnosisStudies: formik.values.diagnosisStudies,
            diagnosticStudies: formik.values.diagnosisStudies,
            pharmacotherapy: formik.values.pharmacotherapy,
            risks: formik.values.risks,

        }

        dispatch(sendUpdate300({
            data: saveData,
            success: () => {
                // dispatch(updateCurrentHistoryItem({
                //     dischargeDate: formik.values.dischargeDate,
                //     disease: formik.values.disease,
                //     doctor: formik.values.doctor,
                //     hospitalWard: formik.values.hospitalWard,
                //     evacuationLocation: formik.values.evacuationLocation?.label || "",
                //     helping: formik.values.helping,
                //     hospitalizationDate: formik.values.hospitalizationDate,
                //     location: formik.values.location?.label || "",
                //     evacuationType: formik.values.evacuationType?.label || "",

                //     diagnosisClinical: formik.values.diagnosisClinical,
                //     diagnosisFinal: formik.values.diagnosisFinal,
                //     diagnosisStudies: formik.values.diagnosisStudies,
                //     pharmacotherapy: formik.values.pharmacotherapy,
                //     risks: formik.values.risks,
                // }));
                /**
                 * Для надёжности перезапросим
                 */
                dispatch(sendGetPatientData({
                    data: {
                        id: currentEvent.idPersonnel,
                    },
                }));

                // dispatch(updatePatientData({
                //     militaryRank: formik.values.militaryRank?.label || "",
                // }));
                setEditMode(false);
            },
        }));
    };

    const handleClickPrint = (tpl, modal) => {
        if (!modal) {
            handlePrint(tpl);
        } else {
            setOpenModal(tpl);
            // handlePrint(tpl);
        }
    }

    const handlePrint = (tpl) => {
        setTplId(tpl);

        setTimeout(() => {
            window.print();
        }, 600);
    }

    const setLocations = (locations) => {
        setLocationsList(locations);



        // setEvacuationLocationsList(locations);








        if (formik.values.location?.label === '') {
            formik.setFieldValue("location", currentHistoryItem.location || "");
        }
        if (formik.values.evacuationLocation?.label === '') {
            formik.setFieldValue("evacuationLocation", currentHistoryItem.evacuationLocation || "");
        }
        if (formik.values.evacuationType?.label === '') {
            formik.setFieldValue("evacuationType", currentHistoryItem.evacuationType || "");
        }
    }

    const setTech = (techList) => {
        setTechList(techList);
        // console.log("formik.values", formik.values);
        if (formik.values.status?.label === '') {
            formik.setFieldValue("status", currentHistoryItem.status || "");
        }
        if (formik.values.referral?.label === '') {
            formik.setFieldValue("referral", currentHistoryItem.referral || "");
        }
    }

    // const setMilitaryRanks = (militaryRanks) => {
    //     setMilitaryRanksList(militaryRanks);
    //     if (formik.values.militaryRank?.label === '') {
    //         formik.setFieldValue("militaryRank", currentHistoryItem.militaryRank || "");
    //     }
    // }

    const handlerDataLoader = async () => {
        let handbook300 = await Handbook300Async();
        let tech = await TechAsync();
        // let handbookMilitary = await MilitaryHandbooksAsync();
        // console.log(handbookMilitary);

        if (handbook300.result) {
            setLocations(handbook300.result.locationMedicalAssistance);
        }

        delete tech.stayMode_Status;
        console.log(tech);

        if (tech) {
            setTech(tech);
        }

        // if (handbookMilitary.result) {
        //     setMilitaryRanks(handbookMilitary.result.militaryRank);
        // }
    }

    useEffect(() => {
        handlerDataLoader();
    }, []);


    const printItems = [
        {
            title: "Амбулаторная карта",
            fullPrint: false,
            modal: false,
            onClick: () => handleClickPrint(3)
        },
        {
            title: "Стационарный лист",
            fullPrint: false,
            modal: false,
            onClick: () => handleClickPrint(4)
        },
        {
            title: "Переводной эпикриз",
            fullPrint: true,
            modal: true,
            onClick: () => handleClickPrint(2, true)
        },
        {
            title: "Выписной эпикриз",
            fullPrint: true,
            modal: true,
            onClick: () => handleClickPrint(1, true)
        },
        {
            title: "Форма 12",
            fullPrint: false,
            modal: false,
            onClick: () => handleClickPrint(5)
        },
        {
            title: "Эвакуационная карта",
            fullPrint: false,
            modal: false,
            onClick: () => handleClickPrint(6)
        },
        {
            title: "Форма 98 (справка о ранении)",
            fullPrint: false,
            modal: false,
            onClick: () => handleClickPrint(7)
        },
    ];

    const handleClickAnalize = (key) => {

        setOpenServiceDialog(key);
    }

    const handleCloseServiceDialog = (event, reason) => {
        if (reason !== "backdropClick") {
            setOpenServiceDialog(false);
        }
    }

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    // console.log(techList);

    return <>

        <Dialog
            fullScreen={fullScreen}
            fullWidth
            maxWidth={"lg"}
            sx={{
                height: "auto",
                minHeight: "500px",
            }}
            open={!!openServiceDialog}
            onClose={handleCloseServiceDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {SERVICES[openServiceDialog]?.title}
            </DialogTitle>
            <DialogContent>
                <iframe
                    src={SERVICES[openServiceDialog]?.url}
                    title={SERVICES[openServiceDialog]?.title}
                    style={{
                        width: "100%",
                        height: "70vh",
                        minHeight: "500px",
                        boxSizing: "border-box"
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseServiceDialog}>Закрыть</Button>
            </DialogActions>
        </Dialog>
        <Dialog
            open={openModal}
            onClose={handleCloseModal}
            PaperProps={{
                component: 'form',
                onSubmit: (event) => {
                    event.preventDefault();
                    const formData = new FormData(event.currentTarget);
                    const modalData = Object.fromEntries(formData.entries());

                    setModalData(modalData);
                    handleCloseModal();
                    handlePrint(openModal);
                    // console.log(modalData);
                },
            }}
        >
            {/* <DialogTitle>Subscribe</DialogTitle> */}
            <DialogContent>

                <ModalStack>
                    <Checkbox
                        inputProps={{
                            name: 'p1',
                        }} />
                    <DialogContentText>
                        Справка о ранении (контузии, травме, увечье), в соответствии с указом Президента РФ<br />№ <ModalTextField name="p1_number" sx={{ width: "90px" }} /> от <ModalTextField name="p1_date" /> г. оформлена.
                    </DialogContentText>
                </ModalStack>

                <ModalStack>
                    <Checkbox
                        inputProps={{
                            name: 'p2',
                        }} />
                    <DialogContentText>
                        Справка на социальные выплаты в соответствии с Указом Президента РФ не оформлялась - ВВК не проводилось.
                    </DialogContentText>
                </ModalStack>

                <ModalStack>
                    <Checkbox
                        inputProps={{
                            name: 'p3',
                        }} />
                    <DialogContentText>
                        Освидетельствован ВВК <ModalTextField name="p3_date" /> года, в соответствии с Перечнем, утвержденным постановлением Правительства Российской Федерации от 29.07.1998 года №855, увечье - в перечень
                        <NativeSelect
                            defaultValue="входит"
                            sx={{ ml: 1 }}
                            inputProps={{
                                name: 'p3_entire',
                                id: 'uncontrolled-native',
                            }}
                        >
                            <option value="входит">входит</option>
                            <option value="не входит">не входит</option>
                        </NativeSelect>
                    </DialogContentText>
                </ModalStack>

                <ModalStack>
                    <Checkbox
                        inputProps={{
                            name: 'p4',
                        }} />
                    <DialogContentText>
                        Медицинское освидетельствование на определение тяжести травмы (увечья) не проводилось.
                    </DialogContentText>
                </ModalStack>

                <ModalStack>
                    <Checkbox
                        inputProps={{
                            name: 'p5',
                        }} />
                    <DialogContentText>
                        Эвакуация:
                        <NativeSelect
                            defaultValue=""
                            sx={{ ml: 1 }}
                            inputProps={{
                                name: 'p5_evaqType',
                                id: 'uncontrolled-native',
                            }}
                        >
                            <option value=""></option>
                            <option value="сидя">сидя</option>
                            <option value="лёжа">лёжа</option>
                        </NativeSelect>,
                        очередь <ModalTextField name="p5_queue" />
                    </DialogContentText>

                </ModalStack>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseModal} variant="contained">Закрыть</Button>
                <Button type="submit" variant="contained">Печатать</Button>
            </DialogActions>
        </Dialog>


        <Dialog
            fullScreen
            open={!!currentHistoryItem.id}
            onClose={handleCloseHistoryItem}
            TransitionComponent={Transition}
        >
            {tplId === 1 ? <PrintTpl1 printData={printData} /> : null}
            {tplId === 2 ? <PrintTpl2 printData={printData} /> : null}
            {tplId === 3 ? <PrintTpl3 printData={printData} /> : null}
            {tplId === 4 ? <PrintTpl4 printData={printData} /> : null}
            {tplId === 5 ? <PrintTpl5 printData={printData} /> : null}
            {tplId === 6 ? <PrintTpl6 printData={printData} /> : null}
            {tplId === 7 ? <PrintTpl7 printData={printData} /> : null}

            <div className="no-print">
                <AppBar
                    sx={{
                        position: "fixed",
                    }}
                >
                    <Toolbar
                        sx={{
                            justifyContent: "space-between",
                        }}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleCloseHistoryItem}
                            aria-label="close"
                        >
                            <ArrowBackIcon />
                        </IconButton>

                        <Box
                            sx={{ display: "flex" }}
                        >
                            {editMode ?
                                <>
                                    <Button
                                        variant="text"
                                        endIcon={<CancelIcon />}
                                        onClick={handleEdit}
                                        color="inherit"
                                    >
                                        Отменить
                                    </Button>
                                    <Button
                                        variant="text"
                                        endIcon={<SaveIcon />}
                                        onClick={handleClickSave}
                                        color="inherit"
                                    >
                                        Сохранить
                                    </Button>
                                </> :
                                <>
                                    <PrintMenu items={printItems} />
                                    <Button
                                        variant="text"
                                        endIcon={<EditIcon />}
                                        onClick={handleEdit}
                                        color="inherit"
                                    >
                                        Редактировать
                                    </Button>
                                </>

                            }
                        </Box>
                    </Toolbar>
                </AppBar>
                <Box sx={{ m: 10 }}></Box>

                <PatientInfo
                    blockStyles={{
                        textAlign: "left",
                        border: "1px solid lightgray",
                        borderRadius: 2,
                        p: 1,
                        backgroundColor: lightGreen[50],
                        display: "inline-flex",
                    }}
                    editable
                />



                <List>
                    <form onSubmit={formik.handleSubmit}>
                        {
                            Object.keys(model).map(key => <Fragment
                                key={key}>
                                <ListItemComponent
                                    title={model[key]}
                                    currentHistoryItem={currentHistoryItem}
                                    currentEvent={currentEvent}
                                    editMode={editMode}
                                    formik={formik}
                                    // setLocationState={setLocationState}
                                    setEvacuationLocationsList={setEvacuationLocationsList}
                                    evacuationLocationsList={evacuationLocationsList}
                                    locationsList={locationsList}
                                    techList={techList}
                                    setTechList={setTechList}
                                    onClickAnalize={handleClickAnalize}
                                // militaryRanksList={militaryRanksList}
                                />
                                <Divider />
                            </Fragment>)
                        }
                    </form>


                    {editMode ? <Box
                        sx={{ m: 2 }}>
                        <EndIconBtn
                            thema={buttons.collection1}
                            icon={<SaveIcon />}
                            variant={'contained'}
                            value={"Сохранить"}
                            handleClick={handleClickSave}
                        />
                    </Box>
                        :
                        <Box
                            sx={{ m: 2 }}
                        >
                            <PrintMenu
                                items={printItems}
                            />
                        </Box>}
                    <Divider />
                </List>
            </div>
        </Dialog>
    </>;
}

export const HistoryItem = memo(HistoryItemComponent);
