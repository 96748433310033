// Аутентификация пользователя
const urlDef = `/`;
const uriSession = `/session`;
const urlSignIn = `${uriSession}/signIn`;
const urlSignOut = `${uriSession}/signOut`;

// Домашняя страница
const urlHome = `/home`;

// Недавние пациенты
const urlRecent = `/recent`;

// Приемо-сортировочное отделение
const urlPatientRegistration = `/registration`;
const urlFormaDeath = `${urlPatientRegistration}/death`;
const urlFormaInjury = `${urlPatientRegistration}/injury`;
const urlFormaDiseases = `${urlPatientRegistration}/diseases`;
const urlFormaTrauma = `${urlPatientRegistration}/trauma`;
const urlFormaNuclear = `${urlPatientRegistration}/nuclear`;
const urlFormaBacterial = `${urlPatientRegistration}/bacterial`;
const urlFormaChemical = `${urlPatientRegistration}/chemical`;
const urlFormaBurn = `${urlPatientRegistration}/burn`;
const urlFormaFrostbite = `${urlPatientRegistration}/frostbite`;
const urlFormaInfectious = `${urlPatientRegistration}/infectious`;
const urlUpload = `/upload`;
const urlStatistic = `/statistic`;

// Журнал личного состава
const urnJournalOfPersonnel = `/journalOfPersonnel`;

// Экспорт
const urlExport = `/export`;

// История заболеваний
const urlHistory = `/history`;

// История заболеваний
const urlHospital = `/hospital`;

// Эвакуационное отделение
const urlEvacuation = `/evacuation`;

// Эвакуационное отделение
const urlPersonnel = `/personnel`;

const urlLosses = `/losses`;

// Управление сервером
const urlManagement = `/management`;
const urlMilitaryUsers = `${urlManagement}/users`;
const urlMilitaryOrganizations = `${urlManagement}/organizations`


export {
    urlHome,
    urlDef, urlSignIn, urlSignOut,
    urlPatientRegistration, urlFormaDeath, urlFormaInjury, urlFormaDiseases, urlFormaTrauma, urlFormaNuclear, urlFormaBacterial,
    urlFormaChemical, urlFormaBurn, urlFormaFrostbite, urlFormaInfectious,
    urnJournalOfPersonnel,
    urlExport,
    urlHistory,
    urlHospital,
    urlRecent,
    urlManagement, urlMilitaryUsers, urlMilitaryOrganizations,
    urlEvacuation,
    urlPersonnel,
    urlUpload,
    urlStatistic,
    urlLosses,
}